import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { C } from '../CodeSnippets'

export function NewPost() {
  useEffect(() => {
    hljs.highlightAll()
  }, [])

  return (
    <section>
      <h1>I finally build the Propeller Clock</h1>

      <p>Since approximatelly 14 years, back when I was starting electric engineering in college, I saw a YouTube video of such a device and I became amazed by it. Back then I was in Argentina I the electric components where much more difficult to get as well as programming a microcontroller was more difficult. I think Arduino didn't exist at that time and the only thing I knew was the PICs series produced by Microchip. Giving that friction and family problems, frustrations at University and so on, I gave up. Until today...</p>

      <p>Motivated by a friend working at IRobot I consider again to do a small project in order to see some phisical stuff moving and not purely software. I decided I could finish my dream on building the propeller clock. Additionally, I though in my son, what can I teach him when he is in his 8s or 10s years old? Is there a way to motivate him to do something cool and not just sit in a sofa watching Tiktok?</p>

      <p>I started working on the propeller clock at the end of 2022, November 2022 maybe, there I was living in Freising, Germany and I found that there was a place called FabLab. There the people (nerd people) meet to build things, they have lot of machines like 3D printers or CNCs that they used to build stuff. They also built a CNC by their own. I started with an ESP32 and tried to programmed with Rust, this has drained lot of my energy and I gave up having all the hardware already mounted, I was unmotivated again. Why Rust? Because I was stupid, Rust libraries for embedded devices are not so finished as the ones with C. I just wanted to use Rust, but this was not the best user case possible. Never again. Just use C (not C++).</p>

      <div className="ratio ratio-16x9">
        <iframe src="https://www.youtube.com/embed/NY4o1uNuZ1s?rel=0" title="Propeller Clock" allowFullScreen></iframe>
      </div>
      <br></br>

      <h1>How I built it</h1>

      <p>In the pictures that follow I will try to explain you how I build it. First notice the wood platform that hold the electric motor with the board with leds and the ESP32 microcontroller. At the botton of the board, where the board is joined with the motor, there are two induction solenoids, this is the way how I powered the boards (LEDs + ESP32). We are going to discussed the circuit in details then. I bought the solenoids in Amazon, it is called <a href="https://www.amazon.de/-/en/DFR0363/dp/B01B0IOAUG/ref=sr_1_1?keywords=DFR0363+Module&sr=8-1" target="_blank" rel="noreferrer">DFR0363 Module</a></p>

      <p>
        <img src="/pics/propeller-clock/1.jpg" alt="Propeller Clock" width="500px"></img>
        <img src="/pics/propeller-clock/2.jpg" alt="Propeller Clock" width="500px"></img>
      </p>

      <p>Here we have the device from another angle, we can see also the power source and the speed regulator for the motor. You can buy any motor you want, consider the torque should be high enough to rotate the board.</p>

      <p>
        <img src="/pics/propeller-clock/3.jpg" alt="Propeller Clock" width="500px"></img>
        <img src="/pics/propeller-clock/4.jpg" alt="Propeller Clock" width="500px"></img>
      </p>

      <p>Another crucial part is the Hall sensor and the magnet, here we can see how they were mounted with more detail. The Hall sensor is the <a href="https://www.amazon.de/-/en/gp/product/B07ZZ8N4VC/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1" target="_blank" rel="noreferrer">KY-035</a> which I also bought on Amazon. I connected it to one analog-to-digital converter (ADC) input port of the ESP32. The ESP32 will be reading that input on every program iteration in order to detect the moment that board passes above the magnet. The goal of this is to measure the rotation speed of the board, this info is needed to properly flash the LEDs at the right time. Note that the magnet should be quite near the Hall sensor, I put two magnets together and rotated them approximately 45 degrees since I notice that the voltage variations where much bigger with in this configuration. You will need to also to experiment and calibrate it since you are going to have different magnets.</p>

      <p>
        <img src="/pics/propeller-clock/5.jpg" alt="Propeller Clock" width="500px"></img>
        <img src="/pics/propeller-clock/6.jpg" alt="Propeller Clock" width="500px"></img>
      </p>

      <h1>The Electric Circuit</h1>

      <p>Let's dig into the electric circuit now. We have as the central brain the ESP32 which is the microcontroller we need to program. This is connected to the 74ACH595 which is an 8-bits shift register. This allows to control 8 outputs (8 LEDs) just using 3 output pins from the ESP32. We could have use 8 output pins directly from the ESP32 since we have quite a few of them. At the moment I started I was unsure if 8 LEDs were enought so I decided to use the 8-bits shift register to be conservative and at the same time I wanted to learn how to control it with the ESP32.</p>

      <p>On the other side we have the KY035 Hall sensor connected to an ADC input port from the ESP32.</p>

      <p>The power sources with 5 V is in all the cases the induccion module. I power it with 6 V on the input since there is some voltage drop in the output and based on my experiments it drops even more when the board is rotation, I saw that in the intensity of the LEDs.</p>

      <img src="/pics/propeller-clock/circuit.png" alt="Circuit" width="800px"></img>

      <h1>The Program (C Language)</h1>

      <p>At the beginning I tried to program it with Rust, but this was a lost of time. Since I was a long time without playing with electric circuits this increased just the possibilities of failing and loosing motivation. That happened in the end and I had to delayed the project one more year until now that I had some free time to play with this device.</p>

      <p>Let's start with the pin setup for the project. We need to use 3 pins as output which are going to be the inputs of the 74ACH595 and 1 pin as ADC connected to the Hall sensor. We achieve this by programming the <b>setup()</b> function</p>

      <C>{
`#include <Arduino.h>

#define CLOCK GPIO_NUM_12
#define LATCH GPIO_NUM_14
#define DATA GPIO_NUM_27
#define HALL GPIO_NUM_25

void setup() {
  Serial.begin(115200);
  pinMode(DATA, OUTPUT);
  pinMode(CLOCK, OUTPUT);
  pinMode(LATCH, OUTPUT);
  pinMode(HALL, INPUT);
}`
      }</C>

      <p>We can now continue with a function that given a number in the range 0-255 translates the binary representation to the 8-LEDs output. This will be a driver that will enable to program the propeller clock easily. For doing this we need to read the <a href="https://www.diodes.com/assets/Datasheets/74HC595.pdf" target="_blank" rel="noreferrer">74ACH595 datasheet </a>. The idea is to write bit by bit in the <b>data</b> pin and on every bit to produce a low-high-low pulse on the <b>clock</b> pin. When the 8-bits are all written we produc a low-high-low pulse on the <b>latch</b> pin. This flushes the result to the outputs of the 74ACH595.</p>

      <C>{`\
void byte_to_leds(uint8_t number) {
  for (uint8_t i = 0; i < 8; i++) {
    digitalWrite(DATA, (number & uint8_t(1 << i)) ? HIGH : LOW);

    digitalWrite(CLOCK, LOW);
    digitalWrite(CLOCK, HIGH);
    digitalWrite(CLOCK, LOW);
  }

  digitalWrite(LATCH, LOW);
  digitalWrite(LATCH, HIGH);
  digitalWrite(LATCH, LOW);
}`
      }</C>

      <p>We can easily test this function by writting the following</p>

      <C>{`\
void loop() {
  byte_to_leds(255);
}`
      }</C>

      <p>Or any other number different than 255. We can observe if the output is correct by looking to the LEDs. We can also test the connections in the circuit. Please before continue make sure this works fine since it is necessary for what it comes next. If it doesn't work statically (without the motor running) then it will be difficult that it works dinamically (rotating at 10 rpm)!</p>

      <p>Now that the output seems to be working fine we have to proceed and measure the speed of the board when it is rotating. The motor that I am using has a speed regulator, you can adjust the speed freely until a limit. I haven't try the maximum speed since the board is not perfectly balance and in my case starts vibrating quite a lot when I am rotating the board at 10 rps. So I have approximately 10 rps but I need to know exactly the value since that value will allow me to know when I should flash the leds, if I flashed at any moment I will not see anything. So we can think this as having discret angles between 0 and 2 PI. In each angle we are going to print a number to the output and this will be repeated in each revolution. This will create an optical illusion and a human should see an image or text there.</p>

      <p>So, how do we measure the rotation speed? In my case I set a maximun voltage level on the pin to be the detection of the magnetic field. I did some experiments and the value of 3,700 V was fine. But I also saw that this was not the case in every rotation, so I had a tolerance for the period measurement, if the period measure was not between 20 and 200 ms then the period was wrong and I don't update anything. This in code would look like the following:</p>

      <C>{`\
int period = 100;
int lastTime = 0;

void loop() {
  int value = analogRead(HALL);

  if (value > 3700) {
    int period_ = millis() - lastTime;
    if (period_ > 20 && period_ < 200) {  
      period = period_;
      lastTime = millis();
    } else if (period_ > 1000) {
      lastTime = millis();
    }
  }
}`
}</C>

      <p>The <b>millis()</b> function returns the time measurment in milliseconds when it is called. Not that we are in the main <b>loop</b> function and we have already the value of the <b>period</b> there. Now we can try to plot something there using the period value and the current time. The idea is to take the module of the time based on the period and scale it by a factor which will be the <i>screen size</i>. This is screen size is the total length that the board will go through every rotation. In that case we know we have a height of 8, because we have 8 LEDs as output in the radial length. Then we can define a width of for example 50 and a figure in a 2D array like the following:</p>

      <C>{`\
int fig[HEIGHT][LENGTH] = {
{1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
{1, 0, 1, 0, 1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,},
};\
`}</C>

      <p>In this array the words <i>hola chau</i> (hello bye in Spanish) can be seen. Now we can code a function that takes the current time, the period and a figure plots it:</p>

      <C>{`\
void draw_fig(int time, int period, int fig[HEIGHT][LENGTH]) {
  int col = time * LENGTH / period;
  int number = 0;
  for (int i = 0; i < HEIGHT; i++) {
    number |= (fig[i][col] << i); 
  }
  byte_to_leds(number);  
}`
}</C>

      <p>We will need to update our <b>loop()</b> function too:</p>

      <C>{`\
void loop() {
  int value = analogRead(HALL);

  if (value > 3700) {
    int period_ = millis() - lastTime;
    if (period_ > 20 && period_ < 200) {  
      period = period_;
      lastTime = millis();
    } else if (period_ > 1000) {
      lastTime = millis();
    }
  }

  draw_fig((millis() - lastTime) % period, period, fig);
}`
}</C>

      <p>And this is all. By powering the induction circuit we should see that the LEDs do a very strange flashing. Then by turning on the motor and running at 10 rps we should see the magic happening. We should see the text represented in the 2D array on the air! Congratulations if you can see it and you make it work. Please write me to my email in Contact section in case you want to show your propeller clock or if you have any question that I can answer.</p>

    </section>
  )
}
