import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Python } from '../CodeSnippets'

export function MinimumCoinsProblem() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Minimum Number of Coins Problem</h1>

            <p>I had an interview and I was ask to implement the following:</p>

            <p>Task description</p>
            <p>Write a function:</p>

            <Python>{`def solution(X, B)`}</Python>

            <p>that, given an integer X as <i>target</i> and a zero-indexed array B consisting of K integers ( a set of coin-denominations), returns how many coins we need at least to return the value X with coins with denominations given by array B. If there is no possible set of coins to end up with integer X, the function should return -1.</p>

            <p>Examples:</p>

            <Python>{`solution(30, [20,10,5]) = 2`}</Python>

            <Python>{`solution(18, [10,6,5,1]) = 3`}</Python>

            <Python>{`solution(10, [7, 5, 1], 0) = 2`}</Python>

            <p>At the beginning I said I would solve it with Go, but then I regret and said I would used Python since I didn't remember basic Go syntax.</p>
            
            <p>I was having trouble until I realized I could used the Breath-First-Search algorithm (BFS). So, look at the first case, how do we form 30 using the 20, 10 and 5 coin denominations. If we start from 30 we can try every posibility from there:</p>

            <Python>{`\
#
#                30
#    10 (30-20)  20 (30-10)  25 (30-5)
#`
            }</Python>

            <p>We can observe that noone is 0, i.e., we need to use more coins on each case. We continue searching:</p>

            <Python>{`\
#
#                      30
#        10            20            25
# -10    0    5  | 0   10   16 | 5   15   20
#`
            }</Python>

            <p>And there we have it! we found two zeros, i.e., two possible solutions in the 2nd try. That means that we need just 2 coins to form 30: 20+10 or 10+20.</p>

            <p>Additionally we found that with 20+20 we got -10, this mean it doesn't make sense to continue searching in this direction.</p>

            <p>The important point here is that this looks like a tree. Even we could not use a tree as a data structure to solve this, the problem looks like a tree transversal algorithms. The idea is go through every <b>tree level</b> first and then continue to the next. This is exactly what Breath-First-Search (BFS) does.</p>

            <p>To program this I knew that recursion makes it much better. The interviewer suggested me to use an iterative solution in a moment when I was blocked before realizing the BFS was a good-to-consider idea. I thought for a moment that maybe but I decided to think again and relax a bit the pressure. Then BFS came to my mind and I implemented this solution:</p>

            <Python>{`\
def min_height(target, array, height):
    if target == 0:
        return height
    if target < 0:
        return -1

    sol = 100000
    for coin in array:
        sol_aux = min_height(target - coin, array, height + 1)
        if sol_aux >= 0:
            sol = min(sol, sol_aux)
    return sol`
            }</Python>

            <p>Then I tested with the use cases they gave me:</p>

            <Python>{`\
assert(min_height(30, [20, 10, 5], 0) == 2)
assert(min_height(18, [10, 6, 5, 1], 0) == 3)
assert(min_height(10, [7, 5, 1], 0) == 2)`
            }</Python>

            <p>And surprinsingly, it worked :)</p>

        </section>
    )
}
