import React  from 'react';

export function SecondDayWithCanoe() {

    return (
        <section>
            <h1>Second day with the Canoe</h1>

            <p>Here are some pics from the second Saturday (02.12.2023)</p>

            <img src="/pics/kanu/kanu-1-02-12-23.jpg" alt="kanu 1" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-2-02-12-23.jpg" alt="kanu 2" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-3-02-12-23.jpg" alt="kanu 3" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-4-02-12-23.jpg" alt="kanu 4" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-5-02-12-23.jpg" alt="kanu 5" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-6-02-12-23.jpg" alt="kanu 6" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-7-02-12-23.jpg" alt="kanu 7" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-8-02-12-23.jpg" alt="kanu 8" width="400px"></img>
            <p></p>

            <img src="/pics/kanu/kanu-9-02-12-23.jpg" alt="kanu 9" width="400px"></img>
        </section>
    )
}
