import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

export function PlayingWithPygame() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Playing With Pygame</h1>

            <p>During the last 2 months I was trying to play with the Python Pygame library for writting games easily. I am not sure what motivate me to do that but anyway I try to do some simple games with it and see how the game logic works. First of all I haven't done any computer game before exept for very simple ones in high school. I remember only that we have used a framework which was very graphical based. Not sure how was it called.</p>

            <p>The first game I wanted to program was a very old one called "Achtung, die Kurve!" ("Take care, the curve" in German). I played this game in the university for the first time in 2012. It is very simple multi-player game where each player has a moving point assing which traces a curve. Players should avoid touching any curve already drawn on the screen. The player that touches on curve or goes out from the screen looses inmideatly. The last player alive wins. One variant of the game creates gaps in the curves every certain period of time. These gaps allow the players to pass through them and this gives more chances to survive in difficult situations. This is how the game I program with Pygame looks like:</p>

            <img src="/pics/achtung_2.gif" alt="Achtung, die Kurve!" width="700px"></img>

            <p>This is <a href="https://github.com/gagiuntoli/achtung_die_kurve">the repository link</a>. The idea behind the code is to keep track of every point where the players when and search after every movement for collisions. For collisions detection one could simply use a O(n) algorithm searching for every point, in this case, I tried to practice a bit an I implemented a quad-tree based algorithm to perform a O(log(n)) search which is much more efficient. Even that I couldn't measure any performace difference since the search doesn't involve a large number of points.</p>

            <p>The second game I programmed was a TETRIS. TETRIS is a must if one is a programmer. This classic game involves collision checks and row clearings. Here you can see my Pygame version of it</p>

            <img src="/pics/tetris_1.gif" alt="TETRIS" width="350px"></img>

            <p>This is <a href="https://github.com/gagiuntoli/tetris_py">the repository link</a>.</p>

            <p>And the most recent one, much more complicated, it is this Wolfenstein based 3d labyrinth, which is in fact not a game. It involves raycasting technics and 3D projections which I found very instructive to learn. <a href="https://www.youtube.com/watch?v=ECqUrT7IdqQ&list=PLZb7kseG3OSZMkt35EOMwsLAbeWMK-QEW&index=1&t=922s">Here</a> there is the tutorial I used to learn about this topics.</p>

            <img src="/pics/labyrinth_2.gif" alt="Labyrinth" width="700px"></img>

            <p>And this is <a href="https://github.com/gagiuntoli/labyrinth">the repository link</a>.</p>

            <p>I found the making of these games a totally funny and inspirational process. I think it is important to learn, at least at a high level, what is involved when these games are being designed. I discovered that is a much more complicated process than what I thought. Even though I think nowadays most of the games are developed engines like Unity which facilitate the work in doing so</p>
        </section>
    )
}
