import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Go, Bash } from '../CodeSnippets'

export function ConcurrencyPaternsInGo() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Concurrency Patterns in Go</h1>

            <Go>{`\
package main

import (
        "fmt"
        "time"
        "math/rand"
)

func main() {
        fmt.Println("Running main goroutine")

        // create the communication channel (send & receive)
        ch := make(chan string)

        // run the boring goroutine
        go boring("guido", ch)

        for i := 0; i < 5; i++ {
                fmt.Println("comming from channel: ", <-ch)
        }

        // let some time to the main goroutine to wait so we can see something
        time.Sleep(time.Duration(2 * time.Second))
}

func boring(msg string, ch chan<- string) {
        for i := 0; ; i++ {
            ch <- fmt.Sprintf("%s %d", msg, i)
            time.Sleep(time.Duration(rand.Intn(1e3)) * time.Millisecond)
        }
}`
            }</Go>

            <Bash>{`\
$ go run main_1.go 
Running main
guido 0
guido 1
guido 2
guido 3
guido 4`
            }</Bash>

            <p>Another way of writting this function could be like by using the so called <b>generator</b>. A generators is a function that generates and returns a channel.</p>

            <Go>{`\
package main

import (
	"fmt"
	"time"
	"math/rand"
)

func main() {
	fmt.Println("Running main")

        // we call a function that returns a channel
	ch := boring("guido")

	for i := 0; i < 5; i++ {
		fmt.Println(<-ch)
	}

        time.Sleep(time.Duration(2 * time.Second))
}

// the function creates and return a channel
func boring(msg string) <-chan string {
	ch := make(chan string)

        // Oh! the function launches a goroutine from inside
	go func() {
		for i := 0; ; i++ {
			ch <- fmt.Sprintf("%s %d", msg, i)
			time.Sleep(time.Duration(rand.Intn(1e3)) * time.Millisecond)
		}
	}()

	return ch
}`
            }</Go>

            <Bash>{`\
$ go run main_2.go 
Running main
guido 0
guido 1
guido 2
guido 3
guido 4`
            }</Bash>

            <p>Reading from a channels is a block operations, i.e, if nothing was <b>sent (ch &lt;- ...)</b> to the channels then <b>receive (... &lt;- ch) </b> call will wait. This leads to race conditions. Consider the following case:</p>

            <Go>{`\
package main

import (
        "fmt"
        "time"
        "math/rand"
)

func main() {
        fmt.Println("Running main")

        ann := boring("Ann")
        bob := boring("Bob")

        // hear we have racing conditions
        // if either bob or ann do not have sent anything then the other needs to way
        for i := 0; i < 5; i++ {
                fmt.Println(<-bob)
                fmt.Println(<-ann)
        }

        time.Sleep(time.Duration(2 * time.Second))
}

func boring(msg string) <-chan string {
        ch := make(chan string)
        go func() {
                for i := 0; ; i++ {
                        ch <- fmt.Sprintf("%s %d", msg, i)
                        time.Sleep(time.Duration(rand.Intn(1e3)) * time.Millisecond)
                }
        }()
        return ch
}`
            }</Go>

            <Bash>{`\
$ go run deadlock_ann_tom.go 
Running main
Bob 0
Ann 0
Bob 1
Ann 1
Bob 2
Ann 2
Bob 3
Ann 3
Bob 4
Ann 4`
            }</Bash>

            <p>Even that from this output we cannot say that Ann or Bob is blocking, we can imagine they are blocking each others at different times</p>

            <h2>Fan In</h2>

            <p>One way of solving the race conditions is using the so called <b>fan in</b>. A fan in consists in a mechanism where several channels send asyncronously to one receiving channel. This is how it would look like:</p>

            <Go>{`\
package main

import (
	"fmt"
	"time"
	"math/rand"
)

func main() {
	fmt.Println("Running main")

	ch := fanIn(boring("Ann"), boring("Bob"))

	for i := 0; i < 10; i++ {
		fmt.Println(<-ch)
	}
}

func fanIn(ch1, ch2 <-chan string) <-chan string {
	ch := make(chan string)
	go func(){for { ch <- <-ch1 } }()
	go func(){for { ch <- <-ch2 } }()
	return ch
}

func boring(msg string) <-chan string {
	ch := make(chan string)
	go func() {
		for i := 0; ; i++ {
			ch <- fmt.Sprintf("%s %d", msg, i)
			time.Sleep(time.Duration(rand.Intn(1e3)) * time.Millisecond)
		}
	}()
	return ch
}`
        }</Go>

            <h2>Async/Mutex</h2>

            <p>So far we have communicated information between goroutines using channels. We haven't lock anything at any time and that is a radical advantages of using channels. The code remains very simple and we don't need to have explicit syncronization checkpoints in our code or worry about shared resources (memory) which could be inconsistent across goroutines (threads).</p>

            <p>In case there is a need, and as a last resource, for using shared resources we can use external or the standard library to create atomic operations, safe data structures or so on. I took the following example from the official Go documentation page, I have only simplified it. We create first a <b>SafeCounter</b> struct which contains the value of the counter and a mutex. The mutex has to main operations: <b>Lock()</b> and <b>Unlock()</b>. You can see how we can be sure that the following 1000 goroutines read and write safetly on the counter:</p>

            <Go>{`\
package main

import (
	"fmt"
	"sync"
	"time"
)

type SafeCounter struct {
	Value int
	mutex sync.Mutex
}

func (counter *SafeCounter) Inc() {
	counter.mutex.Lock()
	defer counter.mutex.Unlock()
	counter.Value++;
}

func main() {
	fmt.Println("Running main")

	counter := SafeCounter{Value: 0, mutex: sync.Mutex{}}

	for i := 0; i < 1000; i++ {
		go counter.Inc()
	}

	time.Sleep(time.Duration(time.Second))
	fmt.Println("Counter Value: ", counter.Value)
}`
            }</Go>

            <p>After running the program, each of the 1000 goroutines should have called <b>Inc()</b> once. The counter should have a value of 1000.</p>

            <Bash>{`\
$ go run mutex.go 
Running main
Counter Value:  1000`
            }</Bash>

        </section>
    )
}
