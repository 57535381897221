import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { Rust, Bash } from '../CodeSnippets'

export default function RC5Cipher() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    const w = <b>w</b>
    const r = <b>r</b>
    const b = <b>b</b>

    return (
        <section>
            <h1>RC5 (Rivest Cipher) Block Cipher</h1>
            <p>
                About one year ago I implemented the RC5 cipher in Rust. The main purpose was to learn and increase my
                knowledge on Rust and cryptography. To learn more about this cipher I leave some references to the
                wikipedia and the original paper written by Rivest ([1] and [2]). Note that on the [original
                paper](https://people.csail.mit.edu/rivest/Rivest-rc5rev.pdf) there is a C implementation of this
                cipher.
            </p>
            <p>This cipher should have some fundamental properties which are shown on the introduction of [2]:</p>
            <ul>
                <li>be a symmetric block cipher, i.e, the same key is used for encryption and decryption.</li>
                <li>
                    be suitable for hardware and software, i.e, the primitives should be feasible to be implemented on
                    most architectures.
                </li>
                <li>
                    be fast, i.e, the operations should be <i>word-oriented</i> in order to achieve the maximum
                    efficiency on a given software. For example, operations using 32-bits integers.
                </li>
                <li>
                    be iterative and have a variable number of rounds that can be adjusted depending of the use case,
                    i.e, a trade-off between security and speed.
                </li>
                <li>
                    have a variable length key for different use cases, i.e, also a trade-off between security and
                    speed.
                </li>
            </ul>
            <p>The RC5 cipher is usually called RC5-w/r/b where:</p>
            <ul>
                <li>{w} = word size in bits (32, 64 or 128 bits)</li>
                <li>{r} = number of rounds (0 to 255)</li>
                <li>{b} = number of 8-bit bytes in the key (0 to 2040 bits)</li>
            </ul>
            <p>
                The default implementation proposed in the paper by Rivest has a word size of 64 bits, 12 rounds and 128
                bits key size.
            </p>
            <p>
                So, how the RC5 cipher works? The encryption and decryption algorithms of the RC5 are quite simple to be
                implemented, whereas the key-expansion step is a bit more complicated part. We are going to start
                describing the encryption and decryption algorithms using the expanded key, and at the end, the
                key-expansion process.
            </p>
            <h2>Encryption</h2>
            <p>
                As any cipher the encryption function, here called encode, should take a key which in this case is the{' '}
                <b>expanded key</b> (<b>S</b>) and the message to encrypt or encode, this is called also{' '}
                <b>plaintext</b>, <b>pt</b> in the code. The <b>encode</b> function is just a wrapper which prepares the
                data to feed to the <b>encode_kernel</b> which is the main encoding function with the whole logic.
            </p>
            <p>
                The data preparation consists of basically transforming the plaintext block <b>pt</b> into two words of
                size <b>w</b>: <b>A</b> & <b>B</b>, this can be 8, 16, 32, 64 or 128 bits. The main encoding function in
                pseudo-code would look like:
            </p>
            <Bash>{`\
A = A + S[0]
B = B + S[1]
for i = 1 to r do
    A = ((A ^ B) << B) + S[2 * i]
    B = ((B ^ A) << A) + S[2 * i + 1]\
            `}</Bash>
            <Rust>{`\
///
/// Encrypts a plaintext \`pt\` and returns a ciphertext \`ct\`.
/// The \`pt\` should have length 2 * w = 2 * bytes(W)
///
/// W: is the data type. Currently supported: u8, u16, u32, u64, u128
/// T: is the key expansion length T = 2 * (r + 1) being r number of rounds. T
/// should be even.
///
/// Example:
///
/// let key = vec![0x00, 0x01, 0x02, 0x03];
/// let pt  = vec![0x00, 0x01];
/// let ct  = vec![0x21, 0x2A];
/// let res = encode::<u8, 26>(key, pt);
/// assert!(&ct[..] == &res[..]);
///
pub fn encode<W, const T: usize>(key: Vec<u8>, pt: Vec<u8>) -> Result<Vec<u8>, Error>
where
    W: Unsigned,
    for<'a> &'a [u8]: TryInto<W::Array>,
{
    let a: W::Array = pt[0..W::BYTES].try_into().map_err(|_| Error::BadLength)?;
    let b: W::Array = pt[W::BYTES..2*W::BYTES].try_into().map_err(|_| Error::BadLength)?;

    let a: W = W::from_le_bytes(a);
    let b: W = W::from_le_bytes(b);

    let [a, b] = encode_kernel::<W, T>(key, [a, b]);

    Ok([W::to_le_bytes(a).as_slice(), W::to_le_bytes(b).as_slice()].concat())
}

pub fn encode_kernel<W, const T: usize>(key: Vec<u8>, pt: [W; 2]) -> [W; 2]
where
    W: Unsigned,
{
    let key_exp = expand_key::<W, T>(key);
    let r = T / 2 - 1;
    let mut a = pt[0] + key_exp[0];
    let mut b = pt[1] + key_exp[1];
    for i in 1..=r {
        a = rotl(a ^ b, b) + key_exp[2 * i];
        b = rotl(b ^ a, a) + key_exp[2 * i + 1];
    }
    [a, b]
}\
            `}</Rust>
            <h2>Decryption</h2>
            <p>
                The decryption works the same as the decryption. In this case we take the <b>ciphertext</b> or <b>ct</b>{' '}
                obtained in the encryption process and the expanded key <b>S</b> and we compute the plaintext again:
            </p>
            <Bash>{`\
for i = r to 1 do
    B = ((B - S[2 * i + 1]) >> A) ^ A
    A = ((A - S[2 * i]) >> B) ^ B
B = B - S[1]
A = A - S[0]
            `}</Bash>
            <p>In the rust code that is:</p>
            <Rust>{`\
///
/// Decrypts a plaintext \`pt\` and returns a ciphertext \`ct\`.
/// The \`pt\` should have length 2 * w = 2 * bytes(W)
///
/// W: is the data type. Currently supported: u8, u16, u32, u64, u128
/// T: is the key expansion length T = 2 * (r + 1) being r number of rounds. T
/// should be even.
///
/// Example:
///
/// let key = vec![0x00, 0x01, 0x02, 0x03];
/// let pt  = vec![0x00, 0x01];
/// let ct  = vec![0x21, 0x2A];
/// let res = decode::<u8, 26>(key, ct);
/// assert!(&ct[..] == &res[..]);
///
pub fn decode<W, const T: usize>(key: Vec<u8>, ct: Vec<u8>) -> Result<Vec<u8>, Error>
where
    W: Unsigned,
    for<'a> &'a [u8]: TryInto<W::Array>,
{
    let a: W::Array = ct[0..W::BYTES].try_into().map_err(|_| Error::BadLength)?;
    let b: W::Array = ct[W::BYTES..2*W::BYTES].try_into().map_err(|_| Error::BadLength)?;

    let a: W = W::from_le_bytes(a);
    let b: W = W::from_le_bytes(b);

    let [a, b] = decode_kernel::<W, T>(key, [a, b]);

    Ok([W::to_le_bytes(a).as_slice(), W::to_le_bytes(b).as_slice()].concat())
}

pub fn decode_kernel<W, const T: usize>(key: Vec<u8>, ct: [W; 2]) -> [W; 2]
where
    W: Unsigned,
{
    let key_exp = expand_key::<W, T>(key);
    let r = T / 2 - 1;
    let mut a = ct[0];
    let mut b = ct[1];
    for i in (1..=r).rev() {
        b = rotr(b - key_exp[2 * i + 1], a) ^ a;
        a = rotr(a - key_exp[2 * i], b) ^ b;
    }
    [a - key_exp[0], b - key_exp[1]]
}\
            `}</Rust>
            <h2>Key-expansion algorithm</h2>
            <p>
                The key expansion consist in converting a key of arbitrary length to an expanded key of size{' '}
                <b>2 * r</b>. This is a much more complex algorithm than the encryption and decryption above. For extra
                details [1] and [2] are very good sources to understand how it works. Basically the algorithm consist on
                3 main steps:
            </p>
            <ol>
                <li>
                    Convert the key from bytes (<b>u8</b>) to words (<b>w: 8, 16, 32, 64 or 128 bits</b>).
                </li>
                <li>
                    Initializing the array <b>S</b>: this step uses magic numbers defined in the paper.
                </li>
                <li>Mixing the secret key.</li>
            </ol>
            <p>Our implementation in Rust is the following:</p>
            <Rust>{`\
///
/// Expands \`key\` into and array of length \`T\` of type \`W\`
///
/// W: is the data type. Currently supported: u8, u16, u32, u64, u128
/// T: is the key expansion length T = 2 * (r + 1) being r number of rounds. T
/// should be even.
///
/// Example:
///
/// let key = vec![0x00, 0x01, 0x02, 0x03];
/// let key_exp = expand_key::<W,T>(key);
///
pub fn expand_key<W, const T: usize>(key: Vec<u8>) -> [W; T]
where
    W: Unsigned,
{
    let mut key_s = [0.into(); T];
    let b = key.len();

    // c = max(1, ceil(8*b/w))
    let c = (std::cmp::max(
        1,
        (8 * key.len() + (W::BITSU32 - 1) as usize) as u32 / W::BITSU32,
    )) as usize;

    // converting the secrey key from bytes to words
    let mut key_l = vec![0.into(); c];
    let u = W::BYTES as usize;
    for i in (0..=(b - 1)).rev() {
        let ix = (i / u) as usize;
        key_l[ix] = (key_l[ix] << 8.into()) + W::from(key[i]);
    }

    // initializing array S
    key_s[0] = W::P;
    for i in 1..=(T - 1) {
        key_s[i] = key_s[i - 1] + W::Q;
    }

    // Mixing in the secret key
    let mut i = 0;
    let mut j = 0;
    let mut a = 0.into();
    let mut b = 0.into();
    for _k in 0..3 * std::cmp::max(c, T) {
        key_s[i] = rotl(key_s[i] + a + b, 3.into());
        a = key_s[i];
        key_l[j] = rotl(key_l[j] + a + b, a + b);
        b = key_l[j];
        i = (i + 1) % T;
        j = (j + 1) % c;
    }
    key_s
}\
            `}</Rust>

            <p>
                The full reference to the code is <a href="https://github.com/gagiuntoli/RC6">here ([3])</a>
            </p>

            <h3>Bibliography</h3>

            <ol type="1">
                <li>
                    [1]. RC5 Wikipedia Reference:{' '}
                    <a href="https://en.wikipedia.org/wiki/RC5">en.wikipedia.org/wiki/RC5</a>
                </li>
                <li>
                    [2]. RC5 Paper:{' '}
                    <a href="https://people.csail.mit.edu/rivest/pubs/Riv94.prepub.pdf">
                        https://people.csail.mit.edu/rivest/pubs/Riv94.prepub.pdf
                    </a>
                </li>
                <li>
                    [3]. GitHub Repository: <a href="https://github.com/gagiuntoli/RC6">github.com/gagiuntoli/RC6</a>
                </li>
            </ol>
        </section>
    )
}
