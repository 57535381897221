import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Bash, Python } from '../CodeSnippets'

export function RoomsChallenge() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Room Challenge</h1>

            <p>Last Wednesday (9th Aug. 2023) I received this challenge for a job position.</p>

            <p>Given a plot like:</p>


            <Bash>{`\
+-----------+------------------------------------+
|           |                                    |
| (closet)  |                                    |
|         P |                            S       |
|         P |         (sleeping room)            |
|         P |                                    |
|           |                                    |
+-----------+    W                               |
|           |                                    |
|        W  |                                    |
|           |                                    |
|           +--------------+---------------------+
|                          |                     |
|                          |                W W  |
|                          |    (office)         |
|                          |                     |
+--------------+           |                     |
|              |           |                     |
| (toilet)     |           |             P       |
|   C          |           |                     |
|              |           |                     |
+--------------+           +---------------------+
|              |           |                     |
|              |           |                     |
|              |           |                     |
| (bathroom)   |           |      (kitchen)      |
|              |           |                     |
|              |           |      W   W          |
|              |           |      W   W          |
|       P      +           |                     |
|             /            +---------------------+
|            /                                   |
|           /                                    |
|          /                          W    W   W |
+---------+                                      |
|                                                |
| S                                   W    W   W |
|                (living room)                   |
| S                                              |
|                                                |
|                                                |
|                                                |
|                                                |
+--------------------------+---------------------+
                           |                     |
                           |                  P  |
                           |  (balcony)          |
                           |                 P   |
                           |                     |
                           +---------------------+\
            `}</Bash>

        <p>You have to count the number of Ws, Ps, Ss and Cs on every room and
        the total also list the room in alphabetical order. For example:</p>

        <Bash>{`\
#  total:
#  W: 14 P: 7 S: 3 C: 1
#  balcony:
#  W: 0 P: 2 S: 0 C: 0
#  bathroom:
#  W: 0 P: 1 S: 0 C: 0
#  closet:
#  W: 0 P: 3 S: 0 C: 0
#  kitchen:
#  W: 4 P: 0 S: 0 C: 0
#  living room:
#  W: 7 P: 0 S: 2 C: 0
#  office:
#  W: 2 P: 1 S: 0 C: 0
#  sleeping room:
#  W: 1 P: 0 S: 1 C: 0
#  toilet:
#  W: 0 P: 0 S: 0 C: 1
#`
            }</Bash>

        <p>This was my solution in Python using a recursive function:</p>

        <Python>{`\
file = open('rooms.txt', 'r')
lines = file.readlines()

ROWS = len(lines);
COLS = len(lines[0]);

WALLS = ["|", "+", "-", "/"] # The \\ should be also here, I couldn't escape it properly
visited = {}

def count_objects(lines, row, col, D, visited):
    if (row,col) not in visited:
        visited[(row,col)] = True
        cell = lines[row][col]
        if cell in ['W', 'P', 'S', 'C']:
            D[cell] += 1
    else:
        return D

    if lines[row+1][col] not in WALLS:
        count_objects(lines, row+1, col, D, visited)
    if lines[row-1][col] not in WALLS:
        count_objects(lines, row-1, col, D, visited)
    if lines[row][col+1] not in WALLS:
        count_objects(lines, row, col+1, D, visited)
    if lines[row][col-1] not in WALLS:
        count_objects(lines, row, col-1, D, visited)

    return D

T = {'W': 0, 'P': 0, 'S': 0, 'C': 0}
R = {}
for row, line in enumerate(lines):
    for j in range(0,len(line)):
        D = {'W': 0, 'P': 0, 'S': 0, 'C': 0}
        if line[j] == '(':
            j += 1
            room_name = ""
            while line[j] != ')':
                room_name += line[j]
                j += 1
            D = count_objects(lines, row, j, D, visited)

            R[room_name] = D
            for key in D:
                T[key] += D[key]

print("total:")
print('W:', T['W'], 'P:', T['P'], 'S:', T['S'],'C:', T['C'])
room_names = []
for room in sorted(R.keys()):
    print(room + ':')
    D = R[room]
    print('W:', D['W'], 'P:', D['P'], 'S:', D['S'],'C:', D['C'])\
        `}</Python>

        </section>
    )
}
