import React  from 'react';
import { BsGithub, BsLinkedin } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'

export default function Contact() {
    return (
        <section>
            <h1>Guido Giuntoli</h1>
            <p>
                <AiOutlineMail /> gagiuntoli@gmail.com
            </p>
            <p>
                <BsGithub /> <a href="https://github.com/gagiuntoli">github.com/gagiuntoli</a>
            </p>
            <p>
                <BsLinkedin /> <a href="https://www.linkedin.com/in/gagiuntoli/">linkedin.com/in/gagiuntoli</a>
            </p>
        </section>
    )
}
