import React  from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax'

export function InlineFormula(props: { eq: string }) {
    return (
        <MathJaxContext>
            <MathJax inline={true}>{'\\(' + props.eq + '\\)'}</MathJax>
        </MathJaxContext>
    )
}
