import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { CPP } from '../CodeSnippets'
export function HackerrankCache() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>HackerRank Challenge: Design a cache</h1>

            <p>The problem states the following:</p>

            <p><i>A cache is a component that stores data so future requests for that data can be served faster. The data stored in a cache might be the results of an earlier computation, or the duplicates of data stored elsewhere. A cache hit occurs when the requested data can be found in a cache, while a cache miss occurs when it cannot. Cache hits are served by reading data from the cache which is faster than recomputing a result or reading from a slower data store. Thus, the more requests that can be served from the cache, the faster the system performs. One of the popular cache replacement policies is: "least recently used" (LRU). It discards the least recently used items first. For example, if a cache with a capacity to store 5 keys has the following state(arranged from most recently used key to least recently used key):</i></p>

            <p><b>5 3 2 1 4</b></p>

            <p><i>Now, If the next key comes as 1(which is a cache hit), then the cache state in the same order will be:</i></p>

            <p><b>1 5 3 2 4</b></p>

            <p><i>Now, If the next key comes as 6(which is a cache miss), then the cache state in the same order will be:</i></p>

            <p><b>6 1 5 3 2</b></p>

            <p>So, I was given the following code:</p>

            <CPP>{`\
#include <iostream>
#include <map>
#include <string>

using namespace std;

struct Node{
    Node* next;
    Node* prev;
    int value;
    int key;
    Node(Node* p, Node* n, int k, int val):prev(p),next(n),key(k),value(val){};
    Node(int k, int val):prev(NULL),next(NULL),key(k),value(val){};
};

class Cache{
protected: 
    map<int,Node*> mp; //map the key to the node in the linked list
    int cp;  //capacity
    Node* tail; // double linked list tail pointer
    Node* head; // double linked list head pointer
    virtual void set(int, int) = 0; //set function
    virtual int get(int) = 0; //get function
};

int main() {
    int n, capacity,i;
    cin >> n >> capacity;
    LRUCache l(capacity);
    for(i=0;i<n;i++) {
        string command;
        cin >> command;
        if(command == "get") {
            int key;
            cin >> key;
            cout << l.get(key) << endl;
        } 
        else if(command == "set") {
            int key, value;
            cin >> key >> value;
            l.set(key,value);
        }
    }
    return 0;
}`}</CPP>

            <p>And I needed to implement the <b></b> abstract class:</p>

            <CPP>{`\
class LRUCache: Cache {
    // ...
}`}</CPP>

            <p>The following was my solution for the problem. At the beggining most of the tests were not passing do to time limits. I was basically having a function called <b>size</b> which went around the linked list and computed it size every time <b>set(...)</b> was called. Then I realize the size can be simply a counter that increases every time a new node is added. Please don't laught.</p>

            <CPP>{`\
class Cache{
protected: 
    map<int,Node*> mp; //map the key to the node in the linked list
    int cp;  //capacity
    Node* tail; // double linked list tail pointer
    Node* head; // double linked list head pointer
    virtual void set(int, int) = 0; //set function
    virtual int get(int) = 0; //get function
};


class LRUCache: Cache {
private:
    int size = 0;
public:
    LRUCache(int _capacity) {
        cp = _capacity;
        head = nullptr;
        tail = nullptr;
    }

    void set(int key, int value) {
        auto it = mp.lower_bound(key);
        Node *node;
        if (it->first == key) {
            // it was already in the list
            node = it->second;
            node->value = value;

            if (node->prev != nullptr) {
                node->prev->next = node->next;
            }

            if (head != node) {
                head->prev = node;
                node->next = head;
                head = node;
            }
            if (tail == node) {
                if (tail->prev != nullptr) {
                    tail = tail->prev;
                }
            }
        } else {
            // it was NOT in the list we have to add it
            if (cp == size) {
                // list is full, the tail will be the head
                node = tail;

                if (tail->prev != nullptr) {
                    tail->prev->next = nullptr;
                    mp.erase(tail->key);
                    tail = tail->prev;
                }

                node->key = key;
                node->value = value;
                node->next = head;
                node->prev = nullptr;
                if (head != nullptr) {
                    head->prev = node;
                    head = node;
                }
            } else {
                // the list has space, we can add a new node as head
                node = new Node(nullptr, head, key, value);
                if (head != nullptr) {
                    head->prev = node;
                }
                head = node;
                if (tail == nullptr) {
                    tail = node;
                }
                size++;
            }

            mp[key] = node;
        }

    }

    int get(int key) {
        if (mp.find(key) == mp.end()) {
            return -1;
        } else {
            return mp[key]->value;
        }
    }
};`}</CPP>
        </section>
    )
}
