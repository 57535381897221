import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { InlineFormula as IF } from '../Math'

import { Rust } from '../CodeSnippets'

export default function ProgrammingBitcoinEllipticCurveCryptography() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Programming Bitcoin</h1>
            <img src="/pics/bitcoin_logo.png" alt="Bitcoin Logo" width="150px"></img>
            <br></br>
            <h2>Elliptic Curve Cryptography</h2>

            <p>
                This post covers chapters 3 and 4 of the book. I decided to join them since chapter 3 covers elliptic
                curves introduction over real numbers, while chapter 4 is over finite fields. We have explained what
                finite field are on <a href="/posts/programming_bitcoin_finite_fields">this</a> post and improved it for
                larger precision on <a href="/posts/programming_bitcoin_finite_fields_improvements">this</a> one.
            </p>

            <p>
                Elliptic curves are equations that, combined with finite fields, allows to define a cyclic groups.
                Groups, apart from fields, have just only one mathematical operation, the addition. Having addition is
                easy to define the multiplication by a scalar.
            </p>

            <p>
                <IF eq="B = A + A + A + \dots + A = s * A" />
            </p>

            <p>
                The fact is that, for elliptic curves defined over finite fields, it is easy and computationally cheap
                to perform the scalar multiplication but it is extremely difficult to reverse it. In other words, given
                the point <IF eq="A" /> and the result <IF eq="B" /> it is difficult to find <IF eq="s" />. This is call{' '}
                <b>discrete logarithm problem</b> and it is important, or maybe crucial, for the security of Bitcoin. We
                will see why in just a second.
            </p>

            <p>Elliptic curves look like this:</p>

            <p>
                <IF eq="y^2 = x^3 + b x + a" />
            </p>

            <p>
                For defining them over a finite field we just make <IF eq="y" />, <IF eq="x" />, <IF eq="a" /> and{' '}
                <IF eq="b" /> to belong to a finite field <IF eq="F_p" />. In the case of Bitcoin the elliptic curve
                used is the <b>secp256k1</b>, (<b>sec</b>: Standard for Efficient Cryptography, <b>p</b>: prime,{' '}
                <b>256</b>: number of bits, <b>k1</b>: Koblitz curve type 1):
            </p>

            <p>
                <IF eq="y^2 = x^3 + 7" />
            </p>

            <p>
                Note that <IF eq="b = 0" /> and <IF eq="a = 7" />. It is assumed that Satoshi avoided the, at that time,
                recommended NIST secp256r1 curve when he released the first Bitcoin version since there were rumors of a
                backdoor trap for it. At the same time the secp256k1 allows to optimize lot of computational operation
                due to the parameters selection while at the same time to keep the same cryptographic security.
            </p>

            <p>The point addition of two points could be visualized as:</p>

            <p>{/* <img src="/posts/point_addition.png" alt="Point Addition" width="250px"></img> */}</p>

            <p>
                There is always one result when the points are different since a line intersect 1 or 3 points all the
                time. There is one exception that is when the points have the same x coordinate but different y. In that
                case we say the 3rd point is the <b>point at infinity</b> or the <b>zero</b> element.
            </p>

            <p>
                We are specially interested in the case the 2 points we add are the same point. This is like multiplying
                by 2 the point. We can observed that for that case we can define the result of the addition as
                intersection point of the tangent line that passes through the point and intersects the curve:
            </p>

            <p>{/* <img src="/posts/scalar_multiplication.png" alt="Scalar Multiplication" width="350px"></img> */}</p>

            <h3>Coding the Point structure</h3>

            <Rust>{`\
#[derive(PartialEq, Debug, Clone)]
pub enum Point {
    Coor {
        a: FiniteField,
        b: FiniteField,
        x: FiniteField,
        y: FiniteField,
    },
    Zero,
}\
        `}</Rust>

            <Rust>{`\
impl Point {
    #[allow(dead_code)]
    fn new(a: &FiniteField, b: &FiniteField, x: &FiniteField, y: &FiniteField) -> Point {
        let point = Point::Coor {
            a: a.clone(),
            b: b.clone(),
            x: x.clone(),
            y: y.clone(),
        };
        if !Self::is_on_curve(&point) {
            panic!("({:?},{:?}) point is not in the curve", x, y);
        }
        point
    }

    pub fn is_on_curve(p: &Point) -> bool {
        match p {
            Point::Coor { a, b, x, y } => {
                return y.clone().pow(BigInt::from(2u32))
                    == x.clone().pow(BigInt::from(3u32)) + a.clone() * x.clone() + b.clone()
            }
            Point::Zero => true,
        }
    }
}\
        `}</Rust>

            <Rust>{`\
impl Add for Point {
    type Output = Point;

    fn add(self, rhs: Point) -> Point {
        match (self.clone(), rhs.clone()) {
            (Point::Zero, _) => return rhs,
            (_, Point::Zero) => return self,
            (
                Point::Coor { a, b, x, y },
                Point::Coor {
                    a: a_rhs,
                    b: b_rhs,
                    x: x_rhs,
                    y: y_rhs,
                    ..
                },
            ) => {
                if a != a_rhs || b != b_rhs {
                    panic!(
                        "The points (x:{:?},y:{:?},a:{:?},b:{:?}) and (x:{:?},y:{:?},a:{:?},b:{:?}) belong to different curves",
                        x, y, a, b, x_rhs, y_rhs, a_rhs, b_rhs
                    );
                }
                if x == x_rhs && y != y_rhs {
                    Point::Zero
                } else if self == rhs && y == x_rhs.clone().scale(BigUint::zero()) {
                    Point::Zero
                } else if x != x_rhs {
                    let s = (y_rhs.clone() - y.clone()) / (x_rhs.clone() - x.clone());
                    let x_res = s.clone().pow(BigInt::from(2u32)) - x.clone() - x_rhs.clone();
                    let y_res = s.clone() * (x.clone() - x_res.clone()) - y;

                    Point::Coor {
                        a,
                        b,
                        x: x_res,
                        y: y_res,
                    }
                } else {
                    let s = (x.clone().pow(BigInt::from(2u32)).scale(BigUint::from(3u32))
                        + a.clone())
                        / (y.clone().scale(BigUint::from(2u32)));
                    let x_res =
                        s.clone().pow(BigInt::from(2u32)) - x.clone().scale(BigUint::from(2u32));
                    let y_res = s * (x - x_res.clone()) - y;
                    return Point::Coor {
                        a,
                        b,
                        x: x_res,
                        y: y_res,
                    };
                }
            }
        }
    }
}\
        `}</Rust>

            <Rust>{`\
impl Point {
    ...

    #[allow(dead_code)]
    pub fn scale(self, _scalar: BigUint) -> Self {
        let mut current = self.clone();
        let mut scalar = _scalar;
        let mut result = Point::Zero;

        while scalar != BigUint::zero() {
            if &scalar & BigUint::one() != BigUint::zero() {
                result = current.clone() + result;
            }
            current = current.clone() + current;
            scalar = scalar >> 1;
        }
        return result;
    }
}\
        `}</Rust>
        </section>
    )
}
