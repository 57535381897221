import React  from 'react';
import Skills from './Skills'
import Experience from './Experience'
import { Courses } from './Courses'
import '../App.css'

function Home() {
    return (
        <body>
            <section>
                <h1>About me</h1>

                <p>
                    As an engineer committed to solving real-world problems, I approach challenges using first
                    principles and pragmatic decision-making. My expertise spans multiple fields, including nuclear
                    engineering and high-performance computing, which have enabled me to work on complex projects like
                    building parallel and distributed software for CFD modeling and developing hybrid scientific code
                    for modeling damage in aeronautical structures. In addition, my work as a high-performance software
                    engineer at Huawei involved evaluating the impact of SVE vectorization on different architectures,
                    and I have experience developing software like smart contracts, backend services, event indexers,
                    and monitoring tools in the blockchain sector. With a decade of experience in technology, I am
                    dedicated to using my skills to engineer innovative solutions that make a positive impact.
                </p>

                <Skills />
                <Experience />
                <Courses />
            </section>
        </body>
    )
}

export default Home
