import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { Bash } from '../CodeSnippets'

export function Esp32Tools() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>ESP32 using Espressif tools</h1>

            <p>This post is about how to use the Espressif tools to program the ESP32. Things we need to do this:</p>

            <ul>
                <li>An ESP32 connected throught USB to the machine we are going to work</li>
                <li>The cross compiler to compile C code to ESP32-compatible binary</li>
                <li>The Espressif toolings: <a href="https://github.com/espressif/esp-idf" target="_blank" rel="noreferrer">Link to GitHub Repository</a></li>
                <li>A computer, in my case a Linux machine running Ubuntu</li>
            </ul>


            <p>Create a suitable working directory to install everything needed</p>

            <Bash>{`\
mkdir $HOME/esp32
cd $HOME/esp32\
            `
            }</Bash>

            <p>Install first the cross compiler, note that the resulting target is a binary for xtensa (Instruction Set Architecture - ISA) and the microcontroller ESP32. We added also to the path:</p>

            <Bash>{`\
wget https://dl.espressif.com/dl/xtensa-esp32-elf-linux64-1.22.0-80-g6c4433a-5.2.0.tar.gz
tar -xzf xtensa-esp32-elf-linux64-1.22.0-80-g6c4433a-5.2.0.tar.gz
export PATH=$PATH:$HOME/esp32/xtensa-esp32-elf/bin
xtensa-esp32-elf-gcc -v\
            `
            }</Bash>

            <p>Download the Espressif <b>esp-idf</b> repository and create the environment variable <b>IDF_PATH</b>:</p>

            <Bash>{`\
git clone --recursive https://github.com/espressif/esp-idf.git
export IDF_PATH=$HOME/esp32/esp-idf
export PATH=$PATH:$HOME/esp32/esp-idf/tools\
            `
            }</Bash>

            <p>Create a Python virtual environment and install dependencies:</p>

            <Bash>{`\
python3 -m venv env
source env/bin/activate.sh
python -m pip install -r requirements.txt\
            `
            }</Bash>
        </section>
    )
}
