import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { MathJax, MathJaxContext } from 'better-react-mathjax'

import { InlineFormula } from '../Math'

import { InlineCode, Rust, Bash } from '../CodeSnippets'

import { rust } from '../commons'

export default function ProgrammingBitcoinFiniteFields() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Programming Bitcoin</h1>

            <img src="/pics/bitcoin_logo.png" alt="Bitcoin Logo" width="150px"></img>
            <br></br>

            <h2>Finite Fields</h2>
            <p>
                With this post I will try to start a deep Bitcoin journey by following the book of Jimmy Song:{' '}
                <a href="https://www.oreilly.com/library/view/programming-bitcoin/9781492031482/">
                    Programming Bitcoin
                </a>
                . I have recently bought it since it caught my attention immediately, why I haven't buy it before - I
                thought. Well, I have just started with Chapter 1 which is titled{' '}
                <a href="https://en.wikipedia.org/wiki/Finite_field">Finite Fields</a>. As the book explains, this topic
                is crucial to understand later how Elliptic Curve and other cryptography algorithms work. My goal with
                this posts is to go through most of the exercises and examples on the book and solve them this time with{' '}
                <a href={rust.url as string}>Rust</a>
            </p>
            <p>
                The examples on Programing Bitcoin are written in Python, I think is a good opportunity for me to learn
                Rust during this process while at the same time and learn more about cryptography and Bitcoin. I want to
                clarify that I am not an expert neither in Rust, nor in Cryptography, nor Bitcoin. I am just learning,
                making these posts is simply something I enjoy. You are free to read and enjoy them too. I would love to
                talk with you about any related topic, also, if you provide me some feedback about :) or want to be my
                friend...
            </p>
            <h3>Introduction</h3>
            <p>
                Let define first what a <b>Finite Field</b> is. A finite field is finite set of elements with addition
                and multiplication operations, also they have additive and multiplicative identities and inverses. They
                are extremely powerful in cryptography, specially asymmetric cryptography and Elliptic Curve
                cryptography which is the one used in Bitcoin.
            </p>
            <p>
                A Finite Field, as its name suggest, should have a finite set of elements. We call the number of
                elements in the field the <b>order</b> of the field. Typically the order is a prime number, i.e. the set
                has a prime number of elements. We could represent for example the finite field{' '}
                <InlineFormula eq="F_{19}" /> (finite field of order 19) as:
            </p>
            <br></br>
            <MathJaxContext>
                <MathJax>{'\\(F_{19} = \\{1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18\\}\\)'}</MathJax>
            </MathJaxContext>
            <br></br>
            <p>
                We need now to define addition and multiplication. This means that any of this operations performs with
                elements in the field should result in another element in the field. We call this property{' '}
                <b>closure</b> or that the field is <b>closed</b> under the addition and multiplication. This means that
                the results of any of these operation should result in another element inside the finite field set. We
                can express this with very simple mathematical formulas:
            </p>
            <MathJaxContext>
                <MathJax>{'\\(a + b = c \\in F \\;\\; \\forall \\; a,b \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>
            <MathJaxContext>
                <MathJax>{'\\(a * b = c \\in F \\;\\; \\forall \\; a,b \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>

            <p>The finite field should have also addition and multiplication identities, in other words:</p>

            <MathJaxContext>
                <MathJax>{'\\(a + 0 = a \\in F \\;\\; \\forall \\; a \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>
            <MathJaxContext>
                <MathJax>{'\\(a * 1 = a \\in F \\;\\; \\forall \\; a \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>

            <p>Finally a finite field should have addition and multiplication inverses:</p>

            <MathJaxContext>
                <MathJax>{'\\(a + (-a) = 0 \\in F \\;\\; \\forall \\; a \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>
            <MathJaxContext>
                <MathJax>{'\\(a * (a^{-1}) = 1 \\in F \\;\\; \\forall \\; a != 0 \\; \\in F\\)'}</MathJax>
            </MathJaxContext>
            <br></br>

            <p>
                For defining the finite fields addition and multiplication operation the modulo operator or modular
                algebra is generally used since the results lie inside the set all the time and make them to satisfy the
                closure property.
            </p>

            <p>
                This was a bit of introduction to Finite Fields as it is presented on the book. Sorry if I was not very
                exact with the definitions. If you are a mathematician you have probably closed this tab already. I
                consider that from a pragmatic point of view of Bitcoin learning this is a good start point and enough.
                We are going to continue defining the basic Bitcoin building blocks and build knowledge in a bottom-up
                way without losing focus on the utility of each Bitcoin brick. Remember that cryptography is the essence
                of how Bitcoin proof users balances. We will come back to it after defining the basic math behind
                Elliptic Curve cryptography.
            </p>

            <h3>Let's start coding</h3>

            <p>
                Start creating a simple Rust project with <InlineCode code="cargo" />. In case you haven't touch Rust
                before, install Rust on your machine following the instructions at the
                <a href={rust.url as string}>official site</a>. I hope that at this point you have realized that Linux
                is superior to other operating systems too and that you are familiar using the command line:
            </p>

            <Bash>{`\
$ cargo init finite_fields
$ cd finite_fields/\
`}</Bash>

            <p>
                Open the <InlineCode code="src/main.rs" /> file with your favorite text editor and start defining a{' '}
                <b>FiniteField</b> structure. It should look like:
            </p>

            <Rust>{`
fn main() {
println!("Hello, world!");
}

#[derive(PartialEq, Debug, Clone, Copy)]
struct FiniteField {
number: u32,
prime: u32,
}

impl FiniteField {
fn new(number: u32, prime: u32) -> Self {
if number >= prime {
panic!(
"Number: {} isn't in the range [0, prime = {})",
number, prime
);
}
FiniteField { number, prime }
}
}\
`}</Rust>

            <p>Note that there is a helper function that allow as to create Finite Field Values as </p>

            <Rust>{`let a = FiniteField(1,19);`}</Rust>

            <p>
                Additionally we derive the <InlineCode code="PartialEq" /> trait to compare two finite fields object,
                i.e. they will be equal if the number and prime are the same. We derive the <InlineCode code="Copy" />{' '}
                and the <InlineCode code="Clone" /> traits too since they will be useful in the future to perform some
                operations, like filling and array with copies of them. We can add some unit tests now to see how the
                PartialEq is working:
            </p>
            <Rust>{`\
#[cfg(test)]
mod tests {
use super::*;

#[test]
fn test_add() {
// a != b
// b == c => a != c
let a = FiniteField::new(6, 11);
let b = FiniteField::new(5, 11);
let c = FiniteField::new(5, 11);

assert_ne!(a, b);
assert_eq!(b, c);
assert_ne!(a, c);
}
}\
`}</Rust>

            <p>
                Run again the <InlineCode code="cargo test" /> command and verify that the test passes.
            </p>

            <p>
                We can now overload the +, - and * operators, this will allow us to write simpler code after when we
                start writing the Elliptic Curve algorithms:
            </p>

            <Rust>
                {`\
impl Add for FiniteField {
type Output = FiniteField;

fn add(self, _rhs: FiniteField) -> FiniteField {
self.check_equal_order_and_panic(&_rhs);

FiniteField {
number: (self.number + _rhs.number) % self.prime,
prime: self.prime,
}
}
}

impl Sub for FiniteField {
type Output = FiniteField;

fn sub(self, rhs: FiniteField) -> FiniteField {
self.check_equal_order_and_panic(&rhs);

if self.number >= rhs.number {
FiniteField {
number: (self.number - rhs.number) % self.prime,
prime: self.prime,
}
} else {
FiniteField {
number: (self.number + self.prime - rhs.number) % self.prime,
prime: self.prime,
}
}
}
}

impl Mul for FiniteField {
type Output = FiniteField;

fn mul(self, rhs: FiniteField) -> FiniteField {
self.check_equal_order_and_panic(&rhs);

FiniteField {
number: (self.number * rhs.number) % self.prime,
prime: self.prime,
}
}
}\
`}
            </Rust>

            <p>
                Mathematically speaking it doesn't make sense to operate with elements from different finite fields,
                i.e., add an element of a finite field of order 9 with another from a field of order 11. We can prevent
                that in our library using a <InlineCode code="panic!" /> which is a non-recoverable error. Note that we
                added the <InlineCode code="self.check_equal_order_and_panic(&rhs)" /> for the +, - and * operators. In
                case we try to do this operations with fields of different orders the code will panic immediately. We
                can define it as:
            </p>

            <Rust>
                {`\
impl FiniteField {
...

fn check_equal_order_and_panic(self: &Self, rhs: &FiniteField) {
if self.prime != rhs.prime {
panic!(
"Finite fields elements have different order lhs: {}, rhs: {}",
self.prime, rhs.prime
)
}
}
}\
`}
            </Rust>

            <p>
                Now we can check that the +, -, and * operators are working properly. Note that we have not mention the
                / operator. This one is much more trickier and we will go a bit into the theory. Check that the
                following unit tests make sense for you.
            </p>

            <Rust>
                {`\
#[cfg(test)]
mod tests {
use super::*;

#[test]
fn test_add() {
let a = FiniteField::new(6, 11);
let b = FiniteField::new(5, 11);
let c = FiniteField::new(0, 11);

assert_eq!(a + b, c);

let a = FiniteField::new(44, 57);
let b = FiniteField::new(33, 57);
let c = FiniteField::new(20, 57);

assert_eq!(a + b, c);

let a = FiniteField::new(17, 57);
let b = FiniteField::new(42, 57);
let c = FiniteField::new(49, 57);
let d = FiniteField::new(51, 57);

assert_eq!(a + b + c, d);
}

#[test]
#[should_panic(expected = "Finite fields elements have different order lhs: 12, rhs: 11")]
fn test_fail_adding_different_orders() {
let a = FiniteField::new(6, 12);
let b = FiniteField::new(5, 11);

let _c = a + b;
}

#[test]
fn test_substract() {
let a = FiniteField::new(6, 11);
let b = FiniteField::new(5, 11);
let c = FiniteField::new(1, 11);

assert_eq!(a - b, c);

let a = FiniteField::new(52, 57);
let b = FiniteField::new(30, 57);
let c = FiniteField::new(38, 57);
let d = FiniteField::new(41, 57);

assert_eq!(a - b - c, d);
}

#[test]
#[should_panic(expected = "Finite fields elements have different order lhs: 12, rhs: 11")]
fn test_fail_substracting_different_orders() {
let a = FiniteField::new(6, 12);
let b = FiniteField::new(5, 11);

let _c = a - b;
}

#[test]
fn test_multiply() {
let a = FiniteField::new(6, 11);
let b = FiniteField::new(5, 11);
let c = FiniteField::new(8, 11);

assert_eq!(a * b, c);
}

#[test]
#[should_panic(expected = "Finite fields elements have different order lhs: 12, rhs: 11")]
fn test_fail_multiplying_different_orders() {
let a = FiniteField::new(6, 12);
let b = FiniteField::new(5, 11);

let _c = a * b;
}
}\
`}
            </Rust>

            <p>
                Note that for each operator we also test that a failing scenario with elements of different fields or
                order. That operation is not valid at any case. Let's continue now solving exercise 4. For this one, we
                will need to code the exponentiation.
            </p>

            <h3>Exercise 4</h3>

            <p>
                Solve these problem in <InlineFormula eq="F_{97}" />
            </p>
            <ul>
                <li>
                    <InlineFormula eq="95 * 45 * 31" />
                </li>
                <li>
                    <InlineFormula eq="17 * 13 * 19 * 44" />
                </li>
                <li>
                    <InlineFormula eq="12^7 * 77^{49}" />
                </li>
            </ul>

            <p>
                We see something new here, the exponentiation. As we now, multiplication is similar to sum several
                times. In the same way exponentiation is like to multiply several times. We can first try a naive
                approach for it:
            </p>

            <Rust>
                {`\
impl FiniteField {
fn pow(self, exp: u32) -> FiniteField {
FiniteField {
number: self.number.pow(exp) % self.prime,
prime: self.prime,
}
}
}\
`}
            </Rust>

            <p>Then, adding this test:</p>

            <Rust>
                {`\
#[test]
fn test_exponentiation() {
let a = FiniteField::new(12, 97);
let b = FiniteField::new(77, 97);
let c = FiniteField::new(63, 97);

assert_eq!(a.pow(7) * b.pow(49), c);
}
}\
`}
            </Rust>

            <p>
                We see that the code explodes with overflow! It makes sense since the intermediate results cannot be
                kept in a 32 bits integer numbers. In the Python examples of the book, this is not a problem since
                Python can handle much bigger precisions. We are going comment this test for now and try to solve the
                issue in a future post. For now our code is able to handle small exponents.
            </p>

            <p>
                We can fix that using the{' '}
                <a href="https://docs.rs/mod_exp/latest/mod_exp/index.html">
                    <InlineCode code="mod_exp" /> library
                </a>
                . <a href="https://en.wikipedia.org/wiki/Modular_exponentiation#Right-to-left_binary_method">Here</a>{' '}
                there is a reference of how it works.
            </p>

            <Rust>
                {`\
impl FiniteField {
... 

fn pow(self, exp: i32) -> FiniteField {
let exp = Self::module(exp, self.prime as i32 - 1);

FiniteField {
number: mod_exp(self.number, exp, self.prime),
prime: self.prime,
}
}

/**
* We define the module operation since the % is the remainder in Rust
* Eg:
* -1 % 2 = -1
* -1 module 2 = 1
*/
fn module(a: i32, b: i32) -> u32 {
(((a % b) + b) % b) as u32
}
}\
`}
            </Rust>

            <p>
                The <InlineCode code="module" /> function is needed since we need the <b>module</b> and not the{' '}
                <b>remainder</b> operation.
            </p>

            <p>We can now cover the exercise 5 and 7 which gives amazing didactical lessons:</p>

            <h3>Exercise 5</h3>

            <p>
                For <InlineFormula eq="k = 1,\;3,\;7,\;13,\;18" />, what is this set in <InlineFormula eq="F_{19}" />{' '}
            </p>

            <p>
                <InlineFormula eq="\{k \cdot 0,\;k \cdot 1, \; k \cdot 2, \; k \cdot 3, \; \dots, \; k \cdot 18 \}" /> ?
            </p>

            <p>
                The answer for this question (please try it by yourself first) is that all the sets are the same. We can
                code it as a unit test:
            </p>

            <Rust>
                {`\
#[test]
fn test_exercise_5() {
let all_elements = (0..19)
.map(|i| FiniteField::new(i, 19))
.collect::<Vec<FiniteField>>();

assert!((0..19)
.map(|i| FiniteField::new(i * 1, 19))
.all(|elem| all_elements.contains(&elem)));

assert!((0..19)
.map(|i| FiniteField::new((i * 3) % 19, 19))
.all(|elem| all_elements.contains(&elem)));

assert!((0..19)
.map(|i| FiniteField::new((i * 7) % 19, 19))
.all(|elem| all_elements.contains(&elem)));

assert!((0..19)
.map(|i| FiniteField::new((i * 13) % 19, 19))
.all(|elem| all_elements.contains(&elem)));

assert!((0..19)
.map(|i| FiniteField::new((i * 18) % 19, 19))
.all(|elem| all_elements.contains(&elem)));
}
`}
            </Rust>

            <p>
                All the sets are the same! They contain the same elements, the fact that k is prime is not a requisite
                to make it happen (try for example <InlineFormula eq="k = 4" />){' '}
            </p>

            <h3>Exercise 7</h3>

            <p>
                For <InlineFormula eq="k = 7,\;11,\;17,\;31" />, what is this set in <InlineFormula eq="F_p" />
            </p>

            <p>
                <InlineFormula eq="\{1^{(p-1)},\; 2^{(p-1)},\;  3^{(p-1)},\;  \dots,\;  (p-1)^{(p-1)}\}" /> ?
            </p>

            <p>
                After coding this set you will find that all the elements in the set are equal to the identity (1 in{' '}
                <InlineFormula eq="F_p" />
                ). This is a result of the famous <b>Fermat's little theorem</b> (valid only if p is a prime):
            </p>

            <p>
                <InlineFormula eq="n^{p-1} \;\%\; p = 1" />
            </p>

            <p>We can code the exercise in a test form to prove it:</p>

            <Rust>
                {`\
#[test]
fn test_exercise_7() {
assert!((1..7)
.map(|i| FiniteField::new(i, 7).pow(7 - 1))
.all(|elem| elem == FiniteField::new(1, 7)));

assert!((1..11)
.map(|i| FiniteField::new(i, 11).pow(11 - 1))
.all(|elem| elem == FiniteField::new(1, 11)));

assert!((1..17)
.map(|i| FiniteField::new(i, 17).pow(17 - 1))
.all(|elem| elem == FiniteField::new(1, 17)));

assert!((1..31)
.map(|i| FiniteField::new(i, 31).pow(31 - 1))
.all(|elem| elem == FiniteField::new(1, 31)));
}\
`}
            </Rust>

            <p>
                The Fermat's little theorem helps us to compute the multiplicative inverse (or, more simply put, doing
                divisions). For example, <InlineFormula eq="10 / 17 \in F_{31}" /> would be equivalent to{' '}
                <InlineFormula eq="10 \cdot 17^{-1} \in F_{31}" /> where <InlineFormula eq="17^{-1}" /> is the
                multiplicative inverse of <InlineFormula eq="17^{-1}" /> (<InlineFormula eq="17 \cdot 17^{-1} = 1" />)
            </p>

            <p>As outlined in the book, from the Fermat's little theorem we can derive that:</p>

            <p>
                <InlineFormula eq="b^{-1} = b^{p-2}" />
            </p>

            <p>
                We can now define the division operator by combining the multiplicative and exponent operators in our
                code:
            </p>

            <Rust>{`\
impl Div for FiniteField {
type Output = FiniteField;

fn div(self, rhs: FiniteField) -> FiniteField {
self.check_equal_order_and_panic(&rhs);

self * rhs.pow(self.prime - 2)
}
}\
`}</Rust>

            <p>We can test it with the exercise 8 in the book:</p>

            <Rust>{`\
#[test]
fn test_division() {
let a = FiniteField::new(3, 31);
let b = FiniteField::new(34, 31);
let c = FiniteField::new(4, 31);

assert_eq!(a / b, c);

let one = FiniteField::new(1, 31);
let a = FiniteField::new(17, 31);
let b = FiniteField::new(29, 31);

assert_eq!(one / a.pow(3), b);

let a = FiniteField::new(4, 31);
let b = FiniteField::new(11, 31);
let c = FiniteField::new(13, 31);

assert_eq!(b / a.pow(4), c);
}`
            }</Rust>

            <p>
                Until this point all the unit tests should be passing when you do <InlineCode code="cargo test" />. If
                not, please check the <a href="https://github.com/gagiuntoli/bitcoin_rust">GitHub Repo</a>.
            </p>

            <h3>Conclusions</h3>

            <p>
                We have covered most of the concept in Chapter 2 (Finite Fields) of Programming Bitcoin. I have to say
                it is wonderful and very easy to follow book. There are topics that were still not being covered such as
                handle large and negative exponents. I plan to write a separate por for it to make this one not so long.
                Until now, we have:
            </p>

            <ul>
                <li>defined what a Finite Field is.</li>
                <li>
                    programmed in Rust the addition, subtraction, multiplication, exponentiation and division operator.
                </li>
                <li>solved in Rust some of the exercise of Chapter 2.</li>
                <li>
                    studied and acknowledge Fermat for his <b>Little Theorem</b> and it utility for computing
                    multiplicative inverses <InlineFormula eq="b^{-1}" />.
                </li>
            </ul>

            <h3>Useful Bibliography</h3>

            <ul>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Finite_field">Finite Field Wikipedia</a>
                </li>
                <li>
                    <a href="https://www.oreilly.com/library/view/programming-bitcoin/9781492031482/">
                        Programming Bitcoin - Chapter 1
                    </a>
                </li>
                <li>
                    <a href={rust.url as string}>Rust page</a>
                </li>
                <li>
                    <a href="https://en.wikipedia.org/wiki/Modular_exponentiation#Right-to-left_binary_method">
                        Modular exponentiation: right-to-left binary method.
                    </a>
                </li>
                <li>
                    <a href="https://github.com/gagiuntoli/bitcoin_rust">The GitHub repository with this code</a>
                </li>
            </ul>
        </section>
    )
}
