import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Go, Bash } from '../CodeSnippets'

export function ArbitrageComputationInGo() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Arbitrage Computation in Go</h1>

            <p>I had a coding interview where I needed to compute the arbitrage opportunities. Arbitrage is when it is possible to take advantage of prices differences for certain goods in different markets. This problem can be thought as a graph transversal. We have a starting node and we need to transverse the graph until finding the starting node again. We are not allow to make any cycles or pass through a visited node.</p>

            <p>For example, given the following prices:</p>

            <Go>{`\
var exchange = map[Currency]map[Currency]string{
	USD: {
		USD: "1.00",
		EUR: "0.92",
		GBP: "0.79",
		CHF: "0.89",
	},
	EUR: {
		USD: "1.09",
		EUR: "1.00",
		GBP: "0.86",
		CHF: "0.96",
	},
	GBP: {
		USD: "1.28",
		EUR: "1.19",
		GBP: "1.00",
		CHF: "1.13",
	},
	CHF: {
		USD: "1.13",
		EUR: "1.04",
		GBP: "0.88",
		CHF: "1.00",
	},
}`
            }</Go>

            <p>We can see that we can do the following <b>CHF -&gt; USD -&gt; GBP -&gt; EUR -&gt; CHF</b>, the profit of this is: <b>1.13 x 0.79 x 1.19 x 0.96 = 1.02</b>. Additionally the profit should be rounded by 2 decimal digits and only paths with profits bigger than 1 were valid solutions</p>

            <p>For this I thought that a recursive function could be the easiest way of solving the problem. After starting I was a bit slow and blocked. In a moment the interviewer told me I had only 10 minutes but I didn't even start coding the main recursive function. First I tried with a map for storing the visited nodes, but then the interviewer asked me where I was planning to store the different paths. Then I realized I could use the path to see if a node was there. Finally the code was computing some paths but the profits were very big. Something was bad with the code, but the logic correct. I just needed to fixed it. The interviewer was happy with my solution and I passed to the next round where they are going to evalute my knowledge on concurrency.</p>

            <p>Here is my solution</p>

            <Go>{`\
package main

import (
	"fmt"
	"slices"
	"strconv"
	"math"
)

type Currency int

const (
	USD Currency = iota
	EUR
	GBP
	CHF
)
 
var exchange = map[Currency]map[Currency]string{
	USD: {
		USD: "1.00",
		EUR: "0.92",
		GBP: "0.79",
		CHF: "0.89",
	},
	EUR: {
		USD: "1.09",
		EUR: "1.00",
		GBP: "0.86",
		CHF: "0.96",
	},
	GBP: {
		USD: "1.28",
		EUR: "1.19",
		GBP: "1.00",
		CHF: "1.13",
	},
	CHF: {
		USD: "1.13",
		EUR: "1.04",
		GBP: "0.88",
		CHF: "1.00",
	},
}

type Oportunity struct {
	currencies []Currency
	profit float64
}


func transverse(start, current Currency, profit float64, path []Currency, oportunities *[]Oportunity) {
	if len(path) > 1 {
		if start == current {
			if profit > 1.0 {
				*oportunities = append(*oportunities, Oportunity{currencies: path, profit: profit})
			}
			return
		}
	}

	adjs := exchange[current];

	for currency, value := range adjs {
		if currency != current && (currency == start || !slices.Contains(path, currency)) {
			val, _ := strconv.ParseFloat(value, 64)
			new_profit := math.Round(profit * val * 100) / 100.0
			new_path := append(path, currency)

			transverse(start, currency, new_profit, new_path, oportunities)
		}
	}
}

func compute_arbitrages() []Oportunity {
	oportunities := []Oportunity{}
	for start := range exchange {
		path := []Currency{start}
		transverse(start, start, 1.0, path, &oportunities)
	}
	return oportunities
}

func main() {
	solution := compute_arbitrages()
	fmt.Println("solution: ", solution)
}`
            }</Go>

            <p>After running the code we can see that we can find several arbitrage oportunities:</p>

            <Bash>{`\
$ go run main.go 
solution:  [{[0 1 2 3] 1.01} {[0 1 2 3 0] 1.01} {[0 2 0] 1.01} {[0 2 1 3 0] 1.02} {[0 2 1 0] 1.02} {[0 2 3 1 0] 1.01} {[0 2 3 0] 1.01} {[0 3 0] 1.01} {[0 3 1 2 0] 1.02} {[0 3 1 0] 1.01} {[0 3 2 1 0] 1.01} {[1 2 3 0 1] 1.01} {[1 2 3 1] 1.01} {[1 2 0 3] 1.01} {[1 2 0 3 1] 1.02} {[1 2 1] 1.02} {[1 3 0 2 1] 1.01} {[1 0 2 3] 1.02} {[1 0 2 3 1] 1.01} {[1 0 3 2] 1.01} {[1 0 3 2 1] 1.01} {[2 0 1 3] 1.01} {[2 0 2] 1.01} {[2 0 3 1 2] 1.02} {[2 1 3 0 2] 1.02} {[2 1 0 3] 1.03} {[2 1 0 3 2] 1.02} {[2 1 2] 1.02} {[2 3 0 1 2] 1.01} {[2 3 0 2] 1.01} {[2 3 1 0 2] 1.02} {[2 3 1 2] 1.01} {[3 0 2 1] 1.01} {[3 0 2 1 3] 1.02} {[3 0 3] 1.01} {[3 0 1 2 3] 1.01} {[3 1 0 2 3] 1.01} {[3 1 0 3] 1.01} {[3 1 2 0] 1.01} {[3 1 2 0 3] 1.01} {[3 2 0 3] 1.01} {[3 2 1 0 3] 1.01} {[3 2 1 3] 1.01}]`
            }</Bash>
        </section>
    )
}
