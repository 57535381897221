import React  from 'react';

export function NewCanoe() {

    return (
        <section>
            <h1>Meine neue Kanu</h1>

            <p>Gestern kam meine neue Kanu und heute mit 0 Grad musste ich auf jeden Fall es an der Havel proviert! (Brandenburg an der Havel)</p>

            <img src="/pics/piragua_1.jpeg" alt="Piragua 1" width="300px"></img>

            <br></br>
            <br></br>
            <p>Wie immer, mein Mate mit</p>

            <img src="/pics/piragua_2.jpeg" alt="Piragua und Mate" width="500px"></img>

            <br></br>
            <br></br>
            <p>Und wieder zurück am Ufer :D</p>

            <img src="/pics/piragua_3.jpeg" alt="Piragua 1" width="300px"></img>
        </section>
    )
}

