export interface Institution {
    name: string
    url: string | null
}

export const independentContractor: Institution = {
    name: 'Independent Contractor',
    url: 'https://en.wikipedia.org/wiki/Employment#Independent_contractor',
}

export const huawei: Institution = {
    name: 'Huawei - HiSilicon',
    url: 'https://en.wikipedia.org/wiki/HiSilicon',
}

export const barcelonaSupercomputingCenter: Institution = {
    name: 'Barcelona Supercomputing Center',
    url: 'https://www.bsc.es/',
}

export const tecna: Institution = {
    name: 'Tecna Nuclear Engineering',
    url: null,
}

export const balseiroInstitute: Institution = {
    name: 'Balseiro Institute',
    url: 'https://en.wikipedia.org/wiki/Balseiro_Institute',
}

export interface Tool {
    name: string
    url: string | null
}

export const grpc: Tool = {
    name: 'gRPC',
    url: 'https://grpc.io/',
}

export const golang: Tool = {
    name: 'Golang',
    url: 'https://go.dev/',
}

export const nodejs: Tool = {
    name: 'Node.js',
    url: 'https://nodejs.org/en',
}

export const SQL: Tool = {
    name: 'SQL',
    url: 'https://es.wikipedia.org/wiki/SQL',
}

export const mongodb: Tool = {
    name: 'MongoDB',
    url: 'https://www.mongodb.com/',
}

export const rust: Tool = {
    name: 'Rust',
    url: 'https://www.rust-lang.org/',
}

export const solidity: Tool = {
    name: 'Solidity',
    url: null,
}

export const typescript: Tool = {
    name: 'TypeScript',
    url: null,
}

export const c: Tool = {
    name: 'C',
    url: null,
}

export const cpp: Tool = {
    name: 'C++',
    url: null,
}

export const polygon: Tool = {
    name: 'Polygon',
    url: 'https://en.wikipedia.org/wiki/Polygon_(blockchain)',
}

export const foundry: Tool = {
    name: 'Foundry',
    url: 'https://book.getfoundry.sh/',
}

export const graphql: Tool = {
    name: 'GraphQL',
    url: null,
}

export const hardhat: Tool = {
    name: 'Hardhat',
    url: 'https://hardhat.org/',
}

export const nestjs: Tool = {
    name: 'NestJs',
    url: 'https://nestjs.com/',
}

export const gem5: Tool = {
    name: 'Gem5',
    url: 'https://en.wikipedia.org/wiki/Gem5',
}

export const cuda: Tool = {
    name: 'CUDA',
    url: 'https://en.wikipedia.org/wiki/CUDA',
}

export const openacc: Tool = {
    name: 'OpenACC',
    url: 'https://en.wikipedia.org/wiki/OpenACC',
}

export const mpi: Tool = {
    name: 'MPI',
    url: 'https://en.wikipedia.org/wiki/Message_Passing_Interface',
}

export const grafana: Tool = {
    name: 'Grafana',
    url: 'https://en.wikipedia.org/wiki/Grafana',
}

export const AWS: Tool = {
    name: 'AWS',
    url: null,
}

export const anvil: Tool = {
    name: 'Anvil',
    url: 'https://book.getfoundry.sh/reference/anvil/',
}

export const linux: Tool = {
    name: 'Linux',
    url: null,
}

export const qemu: Tool = {
    name: 'QEMU',
    url: 'https://www.qemu.org/',
}

export const bash: Tool = {
    name: 'Bash',
    url: null,
}

export const python: Tool = {
    name: 'Python',
    url: null,
}

export const armie: Tool = {
    name: 'ArmIE',
    url: 'https://developer.arm.com/documentation/102190/22-0/Tutorials/Analyze-Scalable-Vector-Extension--SVE--applications-with-Arm-Instruction-Emulator',
}

export const perf: Tool = {
    name: 'Perf',
    url: 'https://en.wikipedia.org/wiki/Perf_(Linux)',
}

export const openmp: Tool = {
    name: 'OpenMP',
    url: null,
}
