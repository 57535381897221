import React  from 'react';
import blockchainExpert from '../files/courses/blockchain_expert.pdf'
import gasOptimization from '../files/courses/gas_optimization.pdf'
import cryptographyStanford from '../files/courses/cryptography_stanford.pdf'
import consensusAlgorithms from '../files/courses/consensus_algorithms.pdf'
import rustUdemy from '../files/courses/rust_udemy.pdf'
import algoexpert from '../files/courses/algoexpert.pdf'
import dappUniversity from '../files/courses/dapp_university.pdf'
import react from '../files/courses/react.pdf'
import buildBlockchain from '../files/courses/build_blockchain.pdf'
import learnEthereum from '../files/courses/learn_ethereum.pdf'
import quantitativeModeling from '../files/courses/quantitative_modeling.pdf'
import cuda from '../files/courses/cuda.pdf'
import algorithmicToolbox from '../files/courses/algorithmic_toolbox.pdf'
import git from '../files/courses/git.pdf'
import cyberattacks from '../files/courses/cyberattacks.pdf'
import interviewingSkills from '../files/courses/saylor_interviewing_skills.pdf'
import golang_basic from '../files/courses/golang_basic_certificate.pdf'
import golang_intermediate from '../files/courses/golang_intermediate_certificate.pdf'
import sql_basic from '../files/courses/sql_basic_certificate.pdf'
import sql_intermediate from '../files/courses/sql_intermediate_certificate.pdf'
import sql_advanced from '../files/courses/sql_advanced_certificate.pdf'
import rest_api_intermediate from '../files/courses/rest_api_intermediate_certificate.pdf'

import codility1 from '../files/awards/codility_1.pdf'
import codility2 from '../files/awards/codility_2.pdf'
import codility3 from '../files/awards/codility_3.pdf'
import codility4 from '../files/awards/codility_4.pdf'
import codility5 from '../files/awards/codility_5.pdf'
import codility6 from '../files/awards/codility_6.pdf'
import codility7 from '../files/awards/codility_7.pdf'
import codility8 from '../files/awards/codility_8.pdf'
import codility9 from '../files/awards/codility_9.pdf'
import codility10 from '../files/awards/codility_10.pdf'
import codility11 from '../files/awards/codility_11.pdf'
import codility12 from '../files/awards/codility_12.pdf'
import codility13 from '../files/awards/codility_13.pdf'
import codility14 from '../files/awards/codility_14.pdf'

export function Courses() {
    return (
        <div>
            <h2>Courses</h2>
            <ul>
                <li>
                    <Certificate
                        date="20.03.2024"
                        title="REST API (Intermediate)"
                        url={rest_api_intermediate}
                    />
                </li>
                <li>
                    <Certificate
                        date="20.03.2024"
                        title="SQL (Advanced)"
                        url={sql_advanced}
                    />
                </li>
                <li>
                    <Certificate
                        date="20.03.2024"
                        title="SQL (Intermediate)"
                        url={sql_intermediate}
                    />
                </li>
                <li>
                    <Certificate
                        date="18.03.2024"
                        title="SQL (Basic)"
                        url={sql_basic}
                    />
                </li>
                <li>
                    <Certificate
                        date="17.03.2024"
                        title="Golang (Intermediate)"
                        url={golang_intermediate}
                    />
                </li>
                <li>
                    <Certificate
                        date="16.03.2024"
                        title="Golang (Basic)"
                        url={golang_basic}
                    />
                </li>
                <li>
                    <Certificate
                        date="05.04.2023"
                        title="Interviewing Skills - Saylor's Academy"
                        url={interviewingSkills}
                    />
                </li>
                <li>
                    <Certificate date="18.01.2023" title="Blockchain Expert - AlgoExpert.io" url={blockchainExpert} />
                </li>
                <li>
                    <Certificate
                        date="15.10.2022"
                        title="Advanced Solidity: Understanding Gas Optimization and Gas Cost"
                        url={gasOptimization}
                    />
                </li>
                <li>
                    <Certificate date="01.04.2022" title="Cryptography I - Stanford" url={cryptographyStanford} />
                </li>
                <li>
                    <Certificate
                        date="24.02.2022"
                        title="Blockchain Scalability and its Foundations in Distributed Systems"
                        url={consensusAlgorithms}
                    />
                </li>
                <li>
                    <Certificate date="29.10.2021" title="Learn Rust by building real applications" url={rustUdemy} />
                </li>
                <li>
                    <Certificate
                        date="14.09.2021"
                        title="Algoexpert.io - Solved 100 Coding Problems"
                        url={algoexpert}
                    />
                </li>
                <li>
                    <Certificate date="04.08.2021" title="Blockchain Developer Bootcamp" url={dappUniversity} />
                </li>
                <li>
                    <Certificate
                        date="01.06.2021"
                        title="React from the beginning - Redux & React Router"
                        url={react}
                    />
                </li>
                <li>
                    <Certificate date="01.06.2021" title="Build a Blockchain & Cryptocurrency" url={buildBlockchain} />
                </li>
                <li>
                    <Certificate
                        date="01.05.2021"
                        title="Master Ethereum and Solidity Programming"
                        url={learnEthereum}
                    />
                </li>
                <li>
                    <Certificate
                        date="01.04.2020"
                        title="Fundamentals of Quantitative Modeling"
                        url={quantitativeModeling}
                    />
                </li>
                <li>
                    <Certificate
                        date="06.12.2019"
                        title="Fundamentals of Accelerated Computing with CUDA C/C++"
                        url={cuda}
                    />
                </li>
                <li>
                    <Certificate date="01.02.2019" title="Algorithmic Toolbox" url={algorithmicToolbox} />
                </li>
                <li>
                    <Certificate date="10.11.2018" title="Version Control with Git" url={git} />
                </li>
                <li>
                    <Certificate date="04.03.2018" title="Introduction to Cyber Attacks" url={cyberattacks} />
                </li>
            </ul>
            <h2>Challenges & Awards</h2>
            <ul>
                <li>
                    <Certificate date="16.07.2022" title="Codility Challenge" url={codility14} />
                </li>
                <li>
                    <Certificate date="12.06.2022" title="Codility Challenge" url={codility13} />
                </li>
                <li>
                    <Certificate date="03.04.2022" title="Codility Challenge" url={codility12} />
                </li>
                <li>
                    <Certificate date="26.02.2022" title="Codility Challenge" url={codility11} />
                </li>
                <li>
                    <Certificate date="29.01.2022" title="Codility Challenge" url={codility10} />
                </li>
                <li>
                    <Certificate date="21.11.2021" title="Codility Challenge" url={codility9} />
                </li>
                <li>
                    <Certificate date="09.10.2021" title="Codility Challenge" url={codility8} />
                </li>
                <li>
                    <Certificate date="11.09.2021" title="Codility Challenge" url={codility7} />
                </li>
                <li>
                    <Certificate date="07.08.2021" title="Codility Challenge" url={codility6} />
                </li>
                <li>
                    <Certificate date="03.07.2021" title="Codility Challenge" url={codility5} />
                </li>
                <li>
                    <Certificate date="08.06.2021" title="Codility Challenge" url={codility4} />
                </li>
                <li>
                    <Certificate date="05.04.2021" title="Codility Challenge" url={codility3} />
                </li>
                <li>
                    <Certificate date="27.03.2021" title="Codility Challenge" url={codility2} />
                </li>
                <li>
                    <Certificate date="02.02.2021" title="Codility Challenge" url={codility1} />
                </li>
            </ul>
        </div>
    )
}

export function Certificate(props: { date: string; title: string; url?: string }) {
    const { date, title, url } = props

    return (
        <span>
            {date} - {url !== undefined ? <a href={url}>{title}</a> : title}
        </span>
    )
}
