import React  from 'react';
export function PatagoniaTrip2024() {
    return (
        <section>
            <h1>Patagonia Trip - March 2024</h1>

            <p>On March I had the possibility to visit Calafate and Chalten :). The trip started with a flight from Rosario to Calafate with my friend Dani from Barcelona. He had already visited Iguazu some days before and now we were going to Patagonia and meet Joel, another friend from Barcelona who travel directly to Patagonia.</p>


            <h2>El Calafate</h2>

            <p>The first full day there, Dani and me booked a walking trip over the Perito Moreno. We went first to the <b>Pasarelas</b> were we saw the glacier from above.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240301_133145.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <p>We tooked then a boat in <b>Bahia Las Sombras</b> who brought us to the starting point to the trip.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240301_155853.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <p>We got some grampons and we were ready to start walking in the ice.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240301_161623.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <p>My words and vocabulary are not enough to describe the beuty and exhitment this landscape has. Not even the pictures. The trip lasted 1:30 h approximatelly and people from different ages did it.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240301_164314.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <p><img src="/pics/patagonia-2024/IMG_20240301_170457.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <h2>Torres del Paine, Chile</h2>

            <p>The next day we went with Joel and Dani to Torres del Paine in Chile with a rented car with the idea of making the trip to the base of Torres del Paine. This was a bad idea since we needed approximatelly 4 hours to arrive there from Calafate. There are two border controls, in Argentina and Chile, and there are additionally non-asfalted roads which makes the trip much longer in time.</p>

            <p>When we arrived there at 12 pm we realized we couldn't to the walk since the cutting time was at 10 am due to safty reasons. At the end, we decided to visit different lakes in the Torres del Paine national park.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240302_121257.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240302_132046.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <h2>El Chalten</h2>

            <p>Next day Joel and Dani made a full boat trip to Perito Moreno and I decided to visit El Chalten alone. Joel and Dani would travel to Usuahia next day and stay there the rest of the trip. We would meet again at the end in Buenos Aires.</p>

            <h3>Day 1 in El Chalten - Laguna Torre</h3>

            <p>The first day I arrived arround 2 pm to El Chalten. It was a 2:30 h trip from Calafate with a bus. I booked quickly a Hostel, left my things and started a walking to Laguna Torre. I ran in some parts being afraid of not having enought day light. At the end I arrived around 17:30 pm, the path was very flat and easy.</p>

            <p>In the way back I meet Rocio and Luz, from Argentina. Coincidentaly Luz lives now in Germany too. We went to have dinner inmediatly after we arrived to Chalten. All of us ate a hamburger with chips, I drank a Coca-Cola and they a beer.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240303_165611.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240303_170902.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240303_170945.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <h3>Day 2 in El Chalten - Laguna de los Tres</h3>

            <p>Before going to sleep in the hostel they guys in my room, Julian (from the Netherlands), David (from the US), Vincenzo (from Napoli) and Florencia (from Argentina) told me that they were planning to start a walk at 3 am to see the sunrise in Laguna de los Tres. Lot of people use to do that since the Fitz Roy has a nice red color at that time. Knowing that I would wake up unvoluntary when they were going to wake up I decided to go with them.</p>

            <p>We started walking and in the middle Florencia went back to Chalten since the wind was very cold and she had short running pants. Now I had to guide the group of 4 without knowing exactly the way and in the dark. Helped by David's mobile phone we checked that the path was the right one on the different checkpoints and we finally arrived some minutes before the sunrise. It was very cold and now clouds where in the sky, the weather conditions were perfect.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240304_070522.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_072226.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_072417.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_073937.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_073948.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_074458.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240304_082321.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <p> He is Julian from the Netherlands, we came back slower than Vincenzo and David. Then I ate an asado (entraña) and he some pasta to recover the energy</p>

            <p><img src="/pics/patagonia-2024/IMG_20240304_113048.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <h3>Day 3 in El Chalten - Loma del Pliegue Tumbado</h3>

            <p>This is a very nice walk, not so many people do this one and that is the nicest part. In the top one has a very beutiful view of Laguna Torre and Cerro Torre. In the way back I meet Mica from Argentina and then we went eating with her friend Laura from Germany. We ate hamburger with chips, I drank a Coca-Cola again and they a beer.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240305_121353.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240305_131311.jpg" alt="Patagonia 2024" width="100%"></img></p>

            <h3>Day 4 in El Chalten - Guido custom walk</h3>

            <p>Next day I booked a transfer from the hostel to Rio Electrico. There I started walking to the south aiming to the same path that joins Chalten with Laguna de los Tres. After 2:30 h I found that intersections and I went to the Laguna Madre e Hija path. There I rest a bit in Laguna Hija and went to the intersection between this path the one that joins Chalten and Laguna Torre. I went back through that path to Chalten. This was the longest, around 20 km, but the easiest.</p>

            <p><img src="/pics/patagonia-2024/IMG_20240306_120152.jpg" alt="Patagonia 2024" width="100%"></img></p>
            <p><img src="/pics/patagonia-2024/IMG_20240306_130752.jpg" alt="Patagonia 2024" width="100%"></img></p>
        </section>
    )
}
