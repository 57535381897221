import React  from 'react';
import 'highlight.js/styles/github-dark-dimmed.css'
import { ReactNode } from 'react'

export function CodeBlock(props: { children: ReactNode}, language: string) {
    return (
        <pre>
            <code style={{ borderStyle: 'solid', borderColor: 'green' }} className={`language-${language}`}>
                {props.children}
            </code>
        </pre>
    )
}

export const Go = (props: { children: ReactNode }) => CodeBlock(props, 'go')
export const C = (props: { children: ReactNode }) => CodeBlock(props, 'c')
export const CPP = (props: { children: ReactNode }) => CodeBlock(props, 'cpp')
export const Bash = (props: { children: ReactNode }) => CodeBlock(props, 'bash')
export const Rust = (props: { children: ReactNode }) => CodeBlock(props, 'rust')
export const Toml = (props: { children: ReactNode }) => CodeBlock(props, 'toml')
export const Python = (props: { children: ReactNode }) => CodeBlock(props, 'python')
export const Dockerfile = (props: { children: ReactNode }) => CodeBlock(props, 'dockerfile')
export const Yaml = (props: { children: ReactNode }) => CodeBlock(props, 'yaml')

export function InlineCode(props: { code: string }) {
    return <code style={{ color: '#e6e2d3', fontWeight: '500' }}>{props.code}</code>
}

