import React  from 'react';
import { ReactNode } from 'react'

export function Quote(props: { children: ReactNode }) {
    return <p style={{ borderStyle: 'solid' }}>{props.children}</p>
}

export default function UsefulQuotesForLife() {
    return (
        <section>
            <h1>Useful Quotes For Life</h1>

            <Quote>
                "The two words <b>information</b> and <b>communication</b> are often used interchangeably, but they
                signify quite different things. Information is giving out; Communication is getting through." - Sydney
                J. Harris
            </Quote>

            <Quote>"You must have chaos within you to give birth to a dancing star." - Friedrich Nietzsche</Quote>

            <Quote>"Pressure makes diamonds" - George S. Patton Jr.</Quote>

            <Quote>
                "Combined with passion and perseverance, identifying and leveraging your strengths and aptitudes is the
                key to any career path." - Anadil Hossain
            </Quote>
        </section>
    )
}
