import React  from 'react';
import '../App.css'

export default function Skills() {
    return (
        <div>
            <h1>Skills</h1>
            <p>
                Programming Languages:
                <ul>
                    <li>
                        <b>Rust</b>
                    </li>
                    <li>
                        <b>Golang</b>
                    </li>
                    <li>
                        <b>C/C++</b>
                    </li>
                    <li>
                        <b>Javascript/Typescript</b>
                    </li>
                    <li>
                        <b>Python</b>
                    </li>
                    <li>
                        <b>Solidity</b>
                    </li>
                </ul>
            </p>
            <p>
                Useful knowledge:
                <ul>
                    <li>
                        <b>High-Perfomance Computing</b>
                    </li>
                    <li>
                        <b>Blockchain Technology</b>
                    </li>
                    <li>
                        <b>Algorithms</b>
                    </li>
                    <li>
                        <b>Parallelization and Distributed Systems</b>
                    </li>
                    <li>
                        <b>Code Optimization</b>
                    </li>
                    <li>
                        <b>GPU programming</b>
                    </li>
                    <li>
                        <b>Cryptography</b>
                    </li>
                    <li>
                        <b>Zero-Knowledge Proofs</b>
                    </li>
                    <li>
                        <b>Version Control Git and Team Programming Practices</b>
                    </li>
                    <li>
                        <b>Embedded Software Development</b>
                    </li>
                </ul>
            </p>
            <p>
                Languages:
                <ul>
                    <li>
                        <b>Spanish (native)</b>
                    </li>
                    <li>
                        <b>English (proficient)</b>
                    </li>
                    <li>
                        <b>German (proficient)</b>
                    </li>
                </ul>
            </p>
        </div>
    )
}
