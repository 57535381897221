import React  from 'react';
import { Nav } from 'react-bootstrap'

interface Post {
    title: string,
    link: string,
    date: string,
}

const posts: Post[] = [
    { title: "DockerCheatsheet ", date: "20-04-2024", link: "/posts/docker-cheatsheet-20-04-2024" },
    { title: "Technical Interview questions about concurrency in Golang", date: "Apr 2024", link: "/posts/golang_concurrency_technical_interview-11-04-2024" },
    { title: "Arbitrage Computation In Go ", date: "Apr 2024", link: "/posts/arbitrage_computation_in_go-11-04-2024" },
    { title: "Concurrency Patterns In Go ", date: "Apr 2024", link: "/posts/concurrency_paterns_in_go-10-04-2024" },
    { title: "Porting Fermi to C++ ", date: "Apr 2024", link: "/posts/porting-fermi-to-cpp-03-04-2024" },
    { title: "Patagonia Trip - March 2024 ", date: "Mar 2024", link: "/posts/patagonia-trip-2024-26-03-2024" },
    { title: "Hackerrank - Cache Design (C++) ", date: "Mar 2024", link: "/posts/hackerrank-cache-24-03-2024" },
    { title: "Minimum Number of Coins (Python) ", date: "Feb 2024", link: "/posts/minimum-coins-problem-21-02-2024" },
    { title: "ESP32 tooling (C Language) ", date: "Jan 2024", link: "/posts/esp32-tools-25-01-2024" },
    { title: "Propeller Clock (C Language) ", date: "Jan 2024", link: "/posts/propeller-clock-23-01-2024" },
    { title: "Second day with the Canoe ", date: "Dec 2023", link: "/posts/second-day-canoe-06-12-2023" },
    { title: "My new Canoe ", date: "Nov 2023", link: "/posts/new-canoe" },
    { title: "Playing with Pygame (Python) ", date: "Nov 2023", link: "/posts/playing-with-pygame" },
    { title: "The Rooms Challenge (Python) ", date: "Nov 2023", link: "/posts/rooms-challenge-12-08-2023" },
    { title: "Running Bitcoin", date: "Jul 2023", link: "/posts/running_bitcoin_regtest" },
    { title: "RC5 Cipher (Rust) ", date: "Apr 2023", link: "/posts/rc5_cipher" },
    { title: "Uniswap Event fetcher (Rust) ", date: "Mar 2023", link: "/posts/uniswap_event_rust_fetcher" },
    { title: "2. Programming Bitcoin - Finite Fields - Improvements (Rust) ", date: "Feb 2023", link: "/posts/programming_bitcoin_finite_fields_improvements" },
    { title: "1. Programming Bitcoin - Finite Fields (Rust) ", date: "Feb 2023", link: "/posts/programming_bitcoin_finite_fields_improvements" },
    { title: "Favourite Quotes", date: "Mar 2023", link: "/posts/useful_quotes_for_life" },
    { title: "Linux Utils", date: "Apr 2023", link: "/posts/useful_linux_tools_and_commands" },
]

function Posts() {
    return (
        <section>
            <h1>Posts</h1>
            <ul>
                {/* Comment for automatically inserted lines (don't delete this comment) */}

                {
                posts.map((post: Post, key: number) => 
                    <div key={key}>
                            <li>
                                <Nav.Link className="nav-link" href={post.link}>
                                    <span>{post.title}</span>
                                    <span style={{ color: 'red' }}>[{post.date}]</span>
                                </Nav.Link>
                            </li>
                        </div>
                    )
                }
            </ul>
        </section>
    )
}

export default Posts
