import React  from 'react';
import ExperienceItem from './ExperienceItem'

import {
    independentContractor,
    huawei,
    barcelonaSupercomputingCenter,
    tecna,
    balseiroInstitute,
    rust,
    typescript,
    graphql,
    gem5,
    nestjs,
    c,
    cpp,
    cuda,
    openacc,
    grafana,
    mpi,
    AWS,
    qemu,
    linux,
    bash,
    python,
    armie,
    perf,
    openmp,
    mongodb,
    SQL,
    nodejs,
    golang,
    grpc,
} from './commons'

import '../App.css'

export default function Experience() {
    return (
        <div>
            <h1>Experience</h1>
            <p>
                <ExperienceItem
                    date={'04.2022 - present'}
                    institution={independentContractor}
                    role={'Software Engineer'}
                    summary={
                        <ul>
                        <li>Developed Computational Fluid Dynamics (<b>CFD</b>) and neutron transport Finite Element Method (<b>FEM</b>) algorithms leveraging parallel computing techniques. Demonstrated proficiency in advanced physics principles, differential equations, and numerical methods to achieve robust and efficient simulations.</li>
                        <li>Development of an open-source <b>C++</b> project featuring <b>GPU</b> acceleration via <b>CUDA</b>, resulting in a 21x enhancement in computation speed. Additionally, good experience using <b>C</b> and managing memory.
                        \item Proficient in employing test-driven development techniques, ensuring rigorous testing of applications through continuous integration/continuous deployment (<b>CI/CD</b>) pipelines to maintain high software quality and reliability.</li>
                        <li>Proficient in employing test-driven development techniques, ensuring rigorous testing of applications through continuous integration/continuous deployment (<b>CI/CD</b>) pipelines to maintain high software quality and reliability.</li>
                        <li>Extensive proficiency in <b>Docker</b> for containerizing applications, adeptly leveraging its capabilities for development, production, and <b>CI/CD</b> environments. Experienced in <b>AWS</b> and cloud computing, committed to finding efficient and cost-optimal solutions.</li>
                        </ul>
                    }
                    skills={[golang, grpc, rust, python, typescript, nodejs, nestjs, AWS, grafana, SQL, mongodb, graphql]}
                />
            </p>
            <p>
                <ExperienceItem
                    date={'04.2020 - 03.2022'}
                    institution={huawei}
                    role={'HPC Engineer'}
                    summary={
                        <ul>
                            <li>
                                Developed proxy applications for GPAW and Gromacs in C/C++ to run on Gem5 and QEMU,
                                investigating the performance of SVE vectorization on ArmV8.
                            </li>
                            <li>
                                Identified and classified the top 10 scientific applications used in business and
                                research facilities for being ported to SVE vectorized code.
                            </li>
                            <li>
                                Developed a new roofline model technique for the ARM Kunpeng chip to measure
                                floating-point performance, using 15 hardware configurations and 5 applications.
                            </li>
                        </ul>
                    }
                    skills={[c, cpp, gem5, qemu, linux, bash, python, armie, perf]}
                />
            </p>
            <p>
                <ExperienceItem
                    date={'09.2015 - 08.2016'}
                    institution={tecna}
                    role={'Nuclear Engineer'}
                    summary={
                        <ul>
                            <li>
                                Designed and developed applications that utilized neutron transport and
                                computational fluid dynamics (CFD) methods to simulate nuclear reactor accidents.
                            </li>
                            <li>
                                Employed fully coupled algorithms to model neutron transport with diffusion methods,
                                fuel exchange in the fuel elements, and the coolant for related water circuits.
                            </li>
                            <li>
                                Innovated a numerical technique that improved the computation of the fuel
                                temperature feedback effect, resulting in a significant reduction in simulation time
                                from one week to just five hours.
                            </li>
                        </ul>
                    }
                    skills={[c, bash, linux]}
                />
            </p>

            <h1>Education</h1>
            <p>
                <ExperienceItem
                    date={'10.2016 - 01.2020'}
                    institution={barcelonaSupercomputingCenter}
                    summary={
                        <div>
                            <ul>
                                <li>
                                    Designed and developed a C++ Finite Element software from scratch to model
                                    micro-structure effects of composite materials, offloading code to GPU with
                                    OpenACC and CUDA for increased performance. Successfully scaled the code up to
                                    160 V100 NVIDIA GPUs and 40 Power9 IBM nodes, resulting in the development of a
                                    highly efficient and scalable software. The code is available on GitHub:
                                    github.com/gagiuntoli/Micropp.
                                </li>
                                <li>
                                    Conducted an extensive literature review to investigate the state-of-the-art
                                    models, challenges, and trends in the field, resulting in the implementation of
                                    a computational solution capable of dealing with real engineering problems.
                                    Developed a hybrid CPU/GPU coupled application with an existing MPI Fortran
                                    software and the GPU micro-scale code designed from scratch, showcasing the
                                    ability to integrate different programming languages and technologies to develop
                                    a more robust and efficient software.
                                </li>
                                <li>
                                    Collaborated with a diverse team of engineers and researchers, including
                                    individuals from the Computer Science Department of Barcelona Supercomputing
                                    Center and the STFC, Daresbury National Laboratory, UK. Demonstrated excellent
                                    teamwork skills and the ability to communicate effectively with colleagues from
                                    different backgrounds and cultures.
                                </li>
                                <li>
                                    Presented research findings in articles, conference talks, and posters,
                                    including a reviewed poster in the Supercomputing 2019 Conference in Denver, US.
                                    Showcased strong communication skills and the ability to effectively disseminate
                                    research findings to the wider scientific community.
                                </li>
                            </ul>
                            <br></br>
                            <span>
                                Thesis link:{' '}
                                <a href="https://upcommons.upc.edu/handle/2117/180785?show=full">
                                    Hybrid CPU/GPU implementation for the FE2 multi-scale method for composite
                                    problems (2020)
                                </a>
                            </span>
                        </div>
                    }
                    role={'PhD Student'}
                    skills={[c, cpp, cuda, openacc, openmp, mpi]}
                />
            </p>
            <p>
                <ExperienceItem
                    date={'04.2010 - 06.2015'}
                    institution={balseiroInstitute}
                    role={'Nuclear Engineer'}
                    summary={
                        <div>
                            <ul>
                                <li>
                                    Designed and developed a parallel CFD software using C, MPI, and PETSC to
                                    simulate particle transport in fluids, resulting in the successful completion of
                                    a thesis on the topic.
                                </li>
                                <li>
                                    Demonstrated expertise in C programming, parallel computing with MPI, and
                                    scientific computing with PETSC.
                                </li>
                                <li>
                                    Validated a parallel MPI Finite Element code to compute Navier-Stokes equations
                                    using PETSc library as the main framework, ensuring accuracy and reliability of
                                    the code.
                                </li>
                                <li>
                                    Implemented an efficient and MPI-based semi-Lagrangian algorithm using an
                                    Octree-based searching technique to model the transport of scalars in fluids,
                                    improving the accuracy and efficiency of the simulation.
                                </li>
                                <li>
                                    Tested the transport code coupling Navier-Stokes and Semi-Lagrangian Advection
                                    in multi-core architectures with MPI up to 16 CPU cores, showcasing the
                                    scalability and parallel efficiency of the developed software.
                                </li>
                            </ul>
                            <br></br>
                            <span>
                                Thesis link:{' '}
                                <a href="https://ricabib.cab.cnea.gov.ar/501/">
                                    Implementation and validation of a finite element code for the transport of
                                    scalars in fluids (2015)
                                </a>
                            </span>
                        </div>
                    }
                    skills={[c, mpi]}
                />
            </p>
            <h1>Scientific Publications</h1>
            <ul>
                <li>
                    <span>
                        Jun. 2023 -{' '}
                        <a href="https://dl.acm.org/doi/10.1145/3592979.3593415">
                            A Massively Parallel Multi-Scale FE2 Framework for Multi-Trillion Degrees of Freedom
                            Simulations
                        </a>
                    </span>
                </li>
                <li>
                    <span>
                        Feb. 2019 -{' '}
                        <a href="https://scholar.google.com/scholar?hl=en&q=G.+Giuntoli%2C+J.+Aguilar%2C+M.+V%C3%A1zquez%2C+S.+Oller%2C+and+G.+Houzeaux.+2019.+A+FE2+multi-scale+implementation+for+modeling+composite+materials+on+distributed+architectures.+Coupled+Sys.+Mech.+8+%282019%29%2C+99%2D%2D109.">
                            A FE2 multi-scale implementation for modeling composite materials on distributed
                            architectures.
                        </a>
                    </span>
                </li>
            </ul>
        </div>
    )
}
