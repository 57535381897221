import React  from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap'

import Pdf from '../resume/guido_giuntoli_resume.pdf'
import Cv from '../cv/guido_giuntoli_cv.pdf'

export default function NavBar() {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/">Home</Navbar.Brand>
                <Nav className="me-auto">
                    <Nav.Link href={Pdf} target="_blank">Resume</Nav.Link>
                    <Nav.Link href={Cv} target="_blank">CV</Nav.Link>
                    <Nav.Link href="/posts">Posts</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}
