import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Bash } from '../CodeSnippets'

export default function UsefulLinuxToolsAndCommands() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Useful Linux Commands and Tools</h1>

            <p>Kill all processes listening to a particular port:</p>

            <Bash>{`sudo fuser -k 3000/tcp`}</Bash>
        </section>
    )
}
