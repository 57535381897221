import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Bash } from '../CodeSnippets'

export function DockerCheatsheet() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])
    return (
        <section>
            <h1>Docker Cheatsheet</h1>

            <p>Attach a bash session to a running container:</p>

            <Bash>{`docker exec -it <container-name> /bin/bash`}</Bash>

            <p>Stop and removing any single docker container:</p>

            <Bash>{`docker ps -aq | xargs docker stop | xargs docker rm`}</Bash>

        </section>
    )
}
