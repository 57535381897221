import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Bash, InlineCode as IC } from '../CodeSnippets'

export function RunningBitcoinInRegtestMode() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Running Bitcoin in Regtest Mode</h1>
            <p>
                The first to run bitcoin-core on the background. I personally keep it running in one tmux panel to see
                the logs and stop it easily when a I desire it.
            </p>
            <p>
                <IC code={'$ bitcoin-cli -regtest'}></IC>
            </p>
            <p>Generate a new wallet:</p>

            <p>
                <IC code={'bitcoin-cli -regtest -named createwallet wallet_name="wallet-01" passphrase="passphrase"'} />
            </p>

            <p>
                You can check that the file <IC code={'~/.bitcoin/regtest/wallets/wallet-01.dat'} /> has been created.
            </p>

            <p>From the wallet (private-key encrypted), you can generate multiple addresses:</p>

            <p>
                <IC code={'$ bitcoin-cli -regtest getnewaddress -rpcwallet="wallet-01'}></IC>
            </p>

            <p>Try to execute it several times</p>

            <p>Mine 101 blocks to an address:</p>

            <p>
                <IC
                    code={'$ bitcoin-cli -regtest generatetoaddress 101 bcrt1q7upftejv5ea3m962l5vyzy8eyqqz5pyfuxe7ph'}
                />
            </p>

            <p>Get the balance of a wallet:</p>

            <p>
                <IC code={'$ bitcoin-cli -regtest -rpcwallet="wallet-01" getbalance'} />
            </p>

            <p>Load a wallet:</p>

            <p>
                <IC code={'$ bitcoin-cli -regtest loadwallet "wallet-01"'} />
            </p>

            <p>List Unspent transactions</p>

            <p>
                <IC code={'$ bitcoin-cli -regtest -rpcwallet="wallet-01" listunspent '} />
            </p>

            <p>Scan UTXO set of <b>any address</b> (not only those belonging to the loaded wallets)</p>

            <Bash>{`\
$ bitcoin-cli scantxoutset start "[\\"addr(bcrt1qekkdf2szkrgdwn44thn4nm006ca05qz3edgsqd)\\"]"                                                                                    

{
  "success": true,
  "txouts": 1376,
  "height": 1414,
  "bestblock": "0a268d85361d76740c46df037a504986c8fc7d2e0bff626a605d942ee32e2994",
  "unspents": [
    {
      "txid": "c6d7d1e13d7b006d186851b75c2484a4c585fc16fc01bd79f3daaf117c1f2ee5",
      "vout": 1,
      "scriptPubKey": "0014cdacd4aa02b0d0d74eb55de759edefd63afa0051",
      "desc": "addr(bcrt1qekkdf2szkrgdwn44thn4nm006ca05qz3edgsqd)#pkwl7nwz",
      "amount": 10.00000000,
      "coinbase": false,
      "height": 1314
    }
  ],
  "total_amount": 10.00000000
}\
            `}</Bash>

            <p>Get descriptor info</p>

            <Bash>{`\
$ bitcoin-cli getdescriptorinfo "addr(bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9)"
{
  "descriptor": "addr(bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9)#xtu8a9fg",
  "checksum": "xtu8a9fg",
  "isrange": false,
  "issolvable": false,
  "hasprivatekeys": false
}\
            `}</Bash>

            <p>Create a watch-only wallet</p>

            <Bash>{`\
$ bitcoin-cli -named createwallet wallet_name=mywallet descriptors=true disable_private_keys=true

{
    "name": "mywalletwatchonly"
}\
            `}</Bash>

            <p>Create descriptor</p>

            <Bash>{`\
$ bitcoin-cli -rpcwallet="mywalletwatchonly" importdescriptors "[{"desc": "addr(bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9)#xtu8a9fg", "internal": false, "watchonly": true, "timestamp": "now" }]"
[
  {
    "success": true
  }
]\
            `}</Bash>
            
            <p>List unspent TXs</p>

            <Bash>{`\
$ guido@HP-250:~/codes/bitcoin$ bitcoin-cli -rpcwallet="mywalletwatchonly" listunspent 0 9999999 "["bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9"]"
[
  {
    "txid": "342499bcbbca469ba2ed14e6eb00b268a71ea53debcb8b68fb1673a567433b1d",
    "vout": 1,
    "address": "bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9",
    "label": "",
    "scriptPubKey": "0014936802e6ade4472aa5e8e18be2d57b4c62f1afa1",
    "amount": 15.00000000,
    "confirmations": 0,
    "ancestorcount": 1,
    "ancestorsize": 5696,
    "ancestorfees": 56960,
    "spendable": true,
    "solvable": false,
    "parent_descs": [
      "addr(bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9)#xtu8a9fg"
    ],
    "safe": false
  },
  {
    "txid": "2a2f879552b170634df7a155f127894ff4aa8a6f964e69469509dfadea7dfd46",
    "vout": 0,
    "address": "bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9",
    "label": "",
    "scriptPubKey": "0014936802e6ade4472aa5e8e18be2d57b4c62f1afa1",
    "amount": 15.00000000,
    "confirmations": 102,
    "spendable": true,
    "solvable": false,
    "parent_descs": [
      "addr(bcrt1qjd5q9e4du3rj4f0gux9794tmf330rtaplqkel9)#xtu8a9fg"
    ],
    "safe": true
  }
]\
            `}</Bash>
            

        </section>
    )
}
