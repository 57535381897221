import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'
import { Go } from '../CodeSnippets'

export function GolangConcurrencyTechnicalInterview() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Golang Concurrency<br></br>Technical Inteview Questions</h1>

            <p>I found this questions <a href="">here</a>. I will try to answer each of them.</p>

            <h2>Concurrency</h2>
            <ul>
                <li>What is concurrency?</li>
                <p><b>Concurrency is the ability of different parts or units of a program, algorithm or problem to be executed out-of-order or in partial-order without afecting the final outcome.</b></p>

                <li>How is it different from parallelism?</li>
                <p><b>Concurrency is about DEALING with lot of things at the same time but parallelism is about DOING lot of things at the same time. For example, a program that is waiting for an HTTP request and during that time computes a square root, it is using concurrency. A program that computes an integral dividing the problem in several parts an each part is computed by different units at the same time, then that is parallelism. Both concurrency and parallelism can exists without the other.</b></p>
            </ul>

            <h2>Race conditions</h2>

            <ul>
                <li>What is a race condition?</li>
                <p><b>A race condition is a condition of a system where his behaviour or outcome depends from the order or timing of different uncontrollable events.</b></p>

                <li>Why is it important?</li>
                <p><b>Race conditions are important since we generally want our systems to behave deterministically with a fix outcome depending of the inputs. We try to avoid non-deterministically results.</b></p>

                <li>What are different types of race conditions?</li>
                <b>
                    <ol>
                        <li><u>Resource Access Race</u>: two or more units access the same shared resource and try to modify or update it concurrently without proper syncronization. This leads to inconsistent corruption. The resource could be the memory, files on disk, databases, network connections, etc.</li>

                        <li><u>Timing Race</u>: the correct behavior of the program depends on the right time execution of each processing unit. For example, one thread relies on another thread to perform something before he can start but there is no guarranty on the execution order.</li>

                        <li><u>State Race</u>: two or more threads modify concurrently the shared state. The program relies on this state to be executed propertly.</li>
                    </ol>
                </b>

                <li>When do race conditions occur?</li>
                <p><b>Race conditions occur typically when two or more threads executes things in a particular and unexpected order causing the result to be different that the one expected. For example, a thread needs to add 1 to value in memory and the other thread reads the value before the write happens and in the program design it was assumed that that should not happened.</b></p>

                <li>How we can prevent it?</li>
                <b>
                    <p>There are different to prevent data races:</p>
                    <ol>
                        <li><u>Syncronization Mechanisms</u>: use locks, mutex, semaphores, etc. to prevent the access to shared resources at the same time.</li>

                        <li><u>Atomic Operation / Thread Safety</u>: use atomic operations implemented on data structures or build thread-safe data structures that can be used concurrently by different threads.</li>

                        <li><u>Immutable Data</u>: trying to use immutable data avoids the write/read concurrency problem across threads.</li>

                        <li><u>Message Passing</u>: opt to send messages across threads, like for example through channels, than using a shared state.</li>

                        <li><u>Thread Confinement</u>: Confine data structures to the thread execution. In this way no other thread can modify it.</li>

                        <li><u>Critical Section Design</u>: minimize scope and duration of the sections where concurrency happens. This allows to find race conditions much more easily if they happen.</li>

                        <li><u>Testing and Debugging</u>: use stress testing, fuzz testing and race condition detections tools to find them. Also static code analysers could help. </li>
                    </ol>
                </b>
            </ul>

            <h2>Synchronization</h2>
            <ul>
                <li>What is memory synchronization?</li>
                <p><b>It is the process to coordinate and organize memory accesses in a concurrent environment with multiple threads.</b></p>

                <li>What problem does it solve?</li>
                <p><b>It solves the data race problem where multiple threads try to access the same data concurrently leaving to non-deterministic results.</b></p>

                <li>How we can achieve it?</li>
                <p><b>Generally by using locks, mutexes or semaphores. Using atomic operations or thread safe data structures.</b></p>

                <li>What benefits and drawbacks does it provide?</li>
                <p><b>It allows to ensure consistency and determinism on the outcome. As a drawback some performance penalty could be observed do to the blocking conditions.</b></p>

                <li>When we should use it?</li>
                <p><b>Everytime when there is a possibility of a data race and when the code is executed concurrently with multiple threads.</b></p>
            </ul>

            <h2>Deadlocks, Livelocks, Starvation</h2>
            <ul>
                <li>What is it?</li>

                <p><b>They are undesired situations that occur in bad designed algorithms or programs that try to syncronize the threads or processes, generally to try to avoid race conditions.</b></p>

                <li>When does it occurs?</li>
                <b>
                    <ol>
                        <li><u>Deadlock</u>: occurs when two or more threads are blocked since they try to access locked resources in an unexpected order.</li>

                        <li><u>Livelock</u>: similar to deadlocks, but in this case the threads try to skip the blocking condition but they cannot make progress.</li>

                        <li><u>Starvation</u>: occurs when a thread or process cannot get access to a resource because other threads or process have access more access priority to it. This could happen for example with some bad designed scheduling policies.</li>
                    </ol>
                </b>

                <li>How we can prevent it?</li>
                <p><b>With careful analysis, design, proper implemented syncronization mechanisms and good scheduling policies.</b></p>

                <li>What is dining philosophers' problem?</li>
                <p><b>The dining philosophers' problem consist in several philosophers sitted in a round table with a fork between them. They can perform 2 actions: eat or think. For eating they need to pick up both: the left and right fork at the same time. If they don't have both they cannot it. The problem is to find the algorithm that makes every philosopher to eat "regularly" and not starve. The interesting part is that lot of simple solutions lead to deadlocks. On the other hand, some other creative and correct solutions solve the problem efficiently.</b></p>
            </ul>

            <h2>Transactions</h2>
            <ul>
                <li>What is a transaction?</li>
                <p><b>A transaction is a unit of work or group of operations that are executed atomically and in isolation from other transactions.</b></p>

                <li>What problem does it solves?</li>
                <p><b>They are typically used for databases to manage concurrent access and updates to shared data. They ensure data consistency and integrity, for example, in a multi-user environment.</b></p>

                <li>How does it affects the application?</li>
                <p><b>The application must implement the logic for managing transaction. This is to allow starting, commiting and rolling-back them.</b></p>

                <li>What is atomicity and how we can achieve it?</li>
                <p><b>Atomicity means that when a transaction is executed there should not be any intermediate state visible to other transactions.</b></p>

                <li>What is isolation and how we can achieve it?</li>
                <p><b>Isolation means that the effect of one transaction is independent on the execution of other transactions. Even in a concurrent execution of transactions, the transaction is executed as it would be the only one running.</b></p>

                <li>What are different isolation levels?</li>
                <p><b>Database management systems (DBMS) provide several isolations level that control the degree of isolation across transactions: "READ UNCOMMITTED" (low isolation), "READ COMMITTED", "REPEATABLE READ", and "SERIALIZABLE" (high-isolation).</b></p>
            </ul>

            <h2>Goroutine</h2>
            <ul>
                <li>What is goroutine?</li>
                <p><b>Is a lightweight thread of execution in Go. Gorutine enable functions to be executed concurrently.</b></p>

                <li>How is it different with Thread/Process/Coroutine/Green Thread?</li>
                <p><b>Goroutines are much lightweight since they are managed by the Go runtime and not by the operating system. The Go runtime multiplexes several OS threads to execute those goroutines concurrently.</b></p>
            </ul>

            <h2>Goroutine Leak</h2>
            <ul>
                <li>What is a goroutine leak and how we can prevent it?</li>
                <b><p>Goroutine leaks happen when the goroutine are not proper terminated during the program execution.</p>
                    <ol>
                        <li>They can be prevented by ensuaring they are terminated after completing a task.</li>

                        <li>By properly closing the channels and cleaning resources asociated with the goroutines.</li>

                        <li>By bounding concurrency and avoid creating an excesive amount of goroutines.</li>

                        <li>Handling errors gracefully by avoiding that the goroutines don't remain infinitely stuck due to unhandled errors or blocking conditions.</li>
                    </ol></b>

                <li>Why is it important?</li>
                <p><b>Because a large number of them could decrease the program performance and increase the resource consumption.</b></p>
            </ul>

            <h3>Race Detection</h3>
            <ul>
                <li>How we can detect it before runtime?</li>
                <p><b>This could be achieved by using some static third-party tools but they can only find a small subgroup of all the race conditions that could exist. Using well-known concurrency pattern and code reviews that prevent this too happend is a much better and efficient approach.</b></p>

                <li>Is there any way to detect race conditions on runtime?</li>
                <p><b>Go has a built in tool that can detect race conditions using the Go runtime which can detect races during code execution: normal run or tests.</b></p>

                <li>How reliable is it?</li>
                <p><b>It is not 100% reliable, only race conditions that happen in the execution path can be detected. The code paths that are not being executed could contain more race conditions that might remain undiscovered.</b></p>

                <li>Do you know how Goroutine scheduler works?</li>

                <p><b>Go creates first a thread pool with "P" threads, each has a queue associated (P-queue). When goroutines are launched they go directly to a global queue. The Go runtime selects to which P-queue the goroutine should be sent. This decision is based on idle P-queues, CPU workload, P-queues length, etc.</b></p>

                <p><b>Additionally the runtime has:</b></p>
                <b>
                    <ol>
                        <li><u>Yielding:</u> The goroutines can yield themselfs from being stopped by the runtime when they are performing some long duration operations like system calls.</li>

                        <li><u>Preemption:</u> To prevent or preemt the monopolization of the CPU resources by some goroutines, the runtime can pause some of them.</li>

                        <li><u>Adaptive Scheduling:</u> The runtime can change the scheduling policy at runtime depending on the workload, for example, increase of decrease the OS-threads in the pool.</li>
                    </ol>
                </b>
            </ul>

            <h2>Channels</h2>
            <ul>
                <li>What is a channel and why do we use it?</li>

                <li>There is a proverb "Don't communicate by sharing memory, share memory by communicating". What does it mean?</li>
                <p><b>That is much more preferable to use channels to communicate threads rather than using syncronization mechanism such as mutexes or semaphores.</b></p>

                <li>What is the difference between buffered and unbuffered channels? and when we use either of them?</li>
                <b>
                    <ol>
                        <li><u>Unbuffered Channels</u>: These are the default ones and they have a size of 0, this means that they do not contain any value internally. The sender cannot send anything if there isn't any other goroutine trying to receive the values. The channel is blocked after sending a value and it remains blocked until the receiver reads reads it. This allows only syncronous execution across goroutines.</li>
                        <Go>{`\
messages := make(chan string)

go func() { fmt.Println(<-messages) }()

messages <- "this is a normal channel"`
                        }</Go>

                        <li><u>Buffered Channels</u>: They have a size bigger than 0, this means that it can store values inside. Thus, the sender blocks itself only when the buffer is full. In the same way, the receiver blocks until he reads all the values in the buffer. This allows asyncronous execution across goroutines.</li>
                        <Go>{`\
// Here we make a channel of strings buffering up to
// 2 values.
messages := make(chan string, 2)

// Because this channel is buffered, we can send these
// values into the channel without a corresponding
// concurrent receive.
messages <- "buffered"
messages <- "channel"

// Later we can receive these two values as usual.
fmt.Println(<-messages)
fmt.Println(<-messages)`
                        }</Go>
                    </ol>
                </b>

                <li>How do read-only/write-only channels help us?</li>
                <p><b>It allows that the compiler detects if a function is using the channel badly. In a receive only channel the function should only read from it (not write to it); whereas in a send only channel the function can only write to it (not read from it).</b></p>

            </ul>

            <h2>sync package</h2>

            <ul>
                <li>What is the use case of sync packages and types? What is the use case of each type in this package?</li>
                <p><b>The "sync" package serves to syncronize gorutine accesses to shared resources. The package contains implementations of:</b></p>
                <b>
                    <ol>
                        <li><u>Mutexes</u>: to provide locking mechanism to shared resources for concurrent access.</li>

                        <li><u>RWMutexes</u>: Allow multiple goroutines to read concurrently from a shared resource whereas the writing is serialized.</li>

                        <li><u>Wait Groups</u>: used to wait for a group of goroutines to finish their execution.</li>

                        <li><u>Conds</u>: they allow a group of goroutine to wait until a certain condition becomes valid.</li>

                        <li><u>Atomic Operation</u>: ensuares that certain operation on shared variable are performed syncronously across goroutines.</li>
                    </ol>
                </b>
            </ul>

            <h2>Patterns</h2>
            <h3>Async</h3>

            <ul>
                <li>We don't have async keywords in golang like some other languages. How can we achieve the same behavior in Go?</li>

                <p><b>We can do something like this:</b></p>
                <b><Go>{`\
func foo(input <-chan int, output chan<- int) {
time.Sleep(time.Duration(time.Second))
output <- <-input
}

func main() {
input := make(chan int)
output := make(chan int)

go foo(input, output)

input <- 2

fmt.Println("output:", <-output)
}`
                }</Go></b>

                <li>Why and where do we need to use this pattern?</li>
                <p><b>This pattern is used in applications where some worker units should compute something that takes a significant amount of time and the application should continue computing things concurrently. For example, a goroutine trying to perform network or IO operations, database queries, etc.</b></p>
            </ul>

            <h3>Queue</h3>
            <ul>
                <li>How can we create a queue in golang?</li>
                <b><Go>{`\
type Queue struct {
items []int
}

func (queue *Queue) Dequeue() int {
if len(queue.items) == 0 {
return -1
}

item := queue.items[0]
queue.items = queue.items[1:]
return item
}

func (queue *Queue) Enqueue(item int) {
queue.items = append(queue.items, item)
}`
                }</Go></b>

                <li>How can we add rate limiting and policy to the queue?</li>
                <p><b>We could add a rate limiter limit how often new values can be enqueued or dequeued.</b></p>

                <li>Does changing sync and sequential process into an async one with queue really help scalability? Why?</li>
                <p><b>Yes, there could be different kind of improvements thanks to the use of an asyncronous queue. Some of them are:</b></p>
                <b>
                    <ol>
                        <li><u>Concurrency</u>: you can execute several processes concurrently without blocking the main thread. This permits to make better use of CPU resources.</li>

                        <li><u>Decoupling</u>: the systems' components become independent of each other, reducing their dependency and allowing better scaling.</li>

                        <li><u>Higher throughput</u>: distributing the work among several asyncronous generally increase the throughput since the usage of resources is maximize.</li>

                        <li><u>Better resource utilization</u>: during high workload spikes the main process can enqueue new tasks for the others workers with extra resources to start working on them.</li>

                        <li><u>Fault tolerant</u>: when a problem arise in one of the tasks or workers in the queue, the other workerks continue doing their tasks without bringing the whole system down.</li>
                    </ol>
                </b>

                <li>How do queues change application behavior?</li>
                <p><b>They execute asyncronously, they show better throughput and one can see better resource utilizations depending on the application and the hardware where it's running.</b></p>

                <li>Why and where do we need to use this pattern?</li>
                <p><b>When we have several tasks that could be potentially be executed asyncronously and independently from each other.</b></p>
            </ul>

            <h3>Pipelines</h3>
            <ul>
                <li>What is a pipeline?</li>
                <p><b>A pipeline is a list of tasks or processes where the output from one of them, except the last one, is the input for next. Go pipelines are typically implemented using channels only.</b></p>

                <li>What are the different ways to implement it?</li>
                <p><b>Functions and channels, struct and methods that represent each stage of the pipeline, interfaces and composition.</b></p>

                <p><b>This is how it would look like using functions and channels, which are the typical way of implementing them:</b></p>

                <b>
                    <Go>{`\
package main

import (
"fmt"
"time"
)

func generate(nums ...int) <-chan int {
output := make(chan int)
go func() {
defer close(output)
for _, n := range nums {
output <- n
}
}()
return output
}

func square(input <-chan int) <-chan int {
output := make(chan int)
go func() {
defer close(output)
for n := range input {
output <- n * n
}
}()
return output
}

func print_(input <-chan int) {
go func() {
for n := range input {
fmt.Printf("%d ", n)
}
fmt.Println()
}()
}

func main() {
nums_ch := generate(1,2,3,4,5,6,7)
nums_squared_ch := square(nums_ch)
print_(nums_squared_ch)

time.Sleep(time.Duration(100 * time.Millisecond))
}`
                    }</Go>
                </b>

                <li>Why and where do we need to use this pattern?</li>
                <p><b>They are needed typically when data needs to be transforms sequentially through several stages. One typical data processing task is called ETL (Extract, Transform and Load).</b></p>

                <p><b>Pipelines provide the following:</b></p>
                <b>
                    <ol>
                        <li><u>Concurrency</u>: increasing the throughput of the whole data processing algorithm.</li>

                        <li><u>Modularity</u>: increasing the separation of concerns of the different pipeline stages.</li>

                        <li><u>Fault tolerant</u>: each stage can properly handle their errors and retry in that case without disrupting the entire pipeline.</li>

                        <li><u>Data streaming</u>: in this processes the incoming data is continuesly processed, analyzed in real time and stored.</li>

                        <li><u>Parallelism</u>: since each stage runs asyncronously the resources are distributed across the resources and the CPU utilization is higher.</li>
                    </ol>
                </b>
            </ul>

            <h3>Fan-In and Fan-Out</h3>
            <ul>
                <li>What is Fan-In/Fan-Out?</li>
                <b>
                    <ol>
                        <li><u>Fan-out</u>: multiple functions read from the same channel until this is closed.</li>

                        <li><u>Fan-in</u>: a single functions reads from several multiplexed channels until the channels are closed.</li>
                    </ol>
                </b>

                <li>How we can implement it?</li>
                <b>
                    <Go>{`\
func worker(id int, jobs chan int) chan int {
output := make(chan int)
go func() {
for job := range jobs {
val := job
fmt.Println("worker", id, "received", val)
output <- (job * job)
}
}()
return output
}

func FanOut(input <-chan int, workers int) []chan int {
inputs := make([]chan int, workers)
for i := 0; i < workers; i++ {
inputs[i] = make(chan int)
go func(i int) {
defer close(inputs[i])
for ch := range input {
inputs[i] <- ch
}
}(i)
}

return inputs
}

func FanIn(outputs []chan int) chan int {
output := make(chan int)

for _, ch := range outputs {
go func(ch chan int) {
defer close(ch)
for c := range ch {
output <- c
}
}(ch)
}

return output
}

func main() {
// solve a problem in parallel using the fan-out fan-in pattern
workers := 3
input := make(chan int)
outputs := make([]chan int, workers)

// fan-out create an array of channels to feed the workers with data
inputs := FanOut(input, workers)

// launch workers and connect the inputs with the outputs
for i := 0; i < workers; i++ {
outputs[i] = worker(i, inputs[i])
}

// fan-in connect all the outputs to a single output
output := FanIn(outputs)

// we send data from the main function
go func() {
defer close(input)
for i := 0; i < 10; i++ {
input <- i
}
}()

// we print the results
for {
select {
case result := <-output:
fmt.Println("Result:", result)
case <- time.After(time.Duration(2 * time.Second)):
fmt.Println("End")
return
}

}
}`
                    }</Go>
                </b>

                <li>Why and where do we need to use this pattern?</li>
                <p><b>Fan-out Fan-in serves to decompose a computationally intensive tasks in several independent ones and execute them in parallel. The task is devided into small ones using the fan-out pattern and the partial results are collected using the fan-in pattern.</b></p>
            </ul>

            <h3>Heartbeats</h3>
            <ul>
                <li>What are Heartbeats?</li>
                <p><b>These are regular signals sent across system's components to indicate that some components are still working properly.</b></p>

                <li>How we can implement it?</li>
                <b><Go>{`\
// This function returns a channel that sends timestamps regularly
func Heartbeats() <-chan time.Time {
heartbeats := make(chan time.Time) 
ticker := time.NewTicker(time.Second)

go func() {
defer ticker.Stop()
for {
select {
case <-ticker.C:
heartbeats <- time.Now() // Send the current time as a heartbeat
}
}
}()
return heartbeats
}

func main() {

heartbeats := Heartbeats()

// we launch a goroutine that check for heartbeats
go func() {
for {
select {
case timestamp := <-heartbeats:
fmt.Println("timestamp:", timestamp)
}
}
}()

select {}
}`
                }</Go>
                </b>

                <li>How it can help us? What benefits and drawbacks does it have?</li>
                <p><b>They could help us to check the health of the system and some components. They introduce more complexity in the systems and the possibility of false positives.</b></p>

                <li>Why and where do we need to use this pattern?</li>
                <b>
                    <ol>
                        <li><u>Health monitoring</u>: across nodes in a distributed system to check which components still work.</li>

                        <li><u>Load balancers</u>: to find worker nodes that are working properly to send new requests.</li>

                        <li><u>Leader election</u>: in those systems that rely on a leader to take decisions. The possible leaders could send heartbeats to indicate that they are working properly and they could be selected.</li>

                        <li><u>Failure detection</u>: for finding problems and errors in our systems.</li>
                    </ol>
                </b>
            </ul>

            <h3>Timeouts and Cancellation</h3>
            <ul>
                <li>Can you describe a scenario where you need to cancel/timeout a Goroutine?</li>
                <li>How we can handle timeouts and cancellations of Goroutines?</li>
                <li>How it can affect application behavior? What do we need to consider?</li>
            </ul>

            <h3>Error propagation</h3>
            <ul>
                <li>How we should propagate errors between Goroutines?</li>

                <li>What happens when we want structured errors?</li>
            </ul>

            <h3>Work stealing</h3>
            <ul>
                <li>What is work stealing?</li>
                <p><b>It is a technique to use better the resources. Each worker or goroutine handles its own queue of tasks and when it becomes idle the work can steal work from others goroutines.</b></p>

                <li>How can we implement it?</li>

                <li>How can it help us?</li>

                <li>Where should we use this?</li>
            </ul>
        </section>
    )
}
