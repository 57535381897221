import React  from 'react';
import NavBar from './components/NavBar'
import Posts from './components/Posts'
import Contact from './components/Contact'
import Home from './components/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import './index.css'

import ProgrammingBitcoinFiniteFields from './components/posts/ProgrammingBitcoinFiniteFields'
import ProgrammingBitcoinFiniteFieldsImprovements from './components/posts/ProgrammingBitcoinFiniteFieldsImprovements'
import ProgrammingBitcoinEllipticCurveCryptography from './components/posts/ProgrammingBitcoinEllipticCurveCryptography'
import { RunningBitcoinInRegtestMode } from './components/posts/RunningBitcoinInRegtestMode'
import RC5Cipher from './components/posts/RC5Cipher'
import RustUniswapEventFetcher from './components/posts/RustUniswapEventFetcher'

import UsefulQuotesForLife from './components/posts/UsefulQuotesForLife'
import UsefulLinuxToolsAndCommands from './components/posts/UsefulLinuxToolsAndCommands'
import { RoomsChallenge } from './components/posts/RoomChallenge-12-08-2023'
import { PlayingWithPygame } from './components/posts/PlayingWithPygame-15-11-2023'
import { NewCanoe } from './components/posts/Canoe-25-11-2023'

//Comment for automatically inserted lines (don't delete this comment)
import { DockerCheatsheet } from './components/posts/docker-cheatsheet-20-04-2024'
import { GolangConcurrencyTechnicalInterview } from './components/posts/golang_concurrency_technical_interview-11-04-2024'
import { ArbitrageComputationInGo } from './components/posts/arbitrage_computation_in_go-11-04-2024'
import { ConcurrencyPaternsInGo } from './components/posts/concurrency_paterns_in_go-10-04-2024'
import { PortingFermiToCpp } from './components/posts/porting-fermi-to-cpp-03-04-2024'
import { PatagoniaTrip2024 } from './components/posts/patagonia-trip-2024-26-03-2024'
import { HackerrankCache } from './components/posts/hackerrank-cache-24-03-2024'
import { MinimumCoinsProblem } from './components/posts/minimum-coins-problem-21-02-2024'
import { Esp32Tools } from './components/posts/esp32-tools-25-01-2024'
import { NewPost } from './components/posts/propeller-clock-23-01-2024'
import { SecondDayWithCanoe } from './components/posts/second-day-canoe-06-12-2023'

function App() {
    return (
        <BrowserRouter>
            <header>
                <NavBar />
            </header>
            <body>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/posts" element={<Posts />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route
                        path="/posts/programming_bitcoin_finite_fields"
                        element={<ProgrammingBitcoinFiniteFields />}
                    />
                    <Route
                        path="/posts/programming_bitcoin_finite_fields_improvements"
                        element={<ProgrammingBitcoinFiniteFieldsImprovements />}
                    />
                    <Route
                        path="/posts/programming_bitcoin_elliptic_curve_cryptography"
                        element={<ProgrammingBitcoinEllipticCurveCryptography />}
                    />
                    <Route path="/posts/uniswap_event_rust_fetcher" element={<RustUniswapEventFetcher />} />
                    <Route path="/posts/running_bitcoin_regtest" element={<RunningBitcoinInRegtestMode />} />
                    <Route path="/posts/rc5_cipher" element={<RC5Cipher />} />

                    <Route path="/posts/useful_quotes_for_life" element={<UsefulQuotesForLife />} />
                    <Route path="/posts/useful_linux_tools_and_commands" element={<UsefulLinuxToolsAndCommands />} />
                    <Route path="/posts/rooms-challenge-12-08-2023" element={<RoomsChallenge />} />
                    <Route path="/posts/playing-with-pygame" element={<PlayingWithPygame />} />
                    <Route path="/posts/new-canoe" element={<NewCanoe />} />

                    { /* Comment 2 for automatically inserted lines (don't delete this comment) */}
                    <Route path="/posts/docker-cheatsheet-20-04-2024" element={<DockerCheatsheet />} />
                    <Route path="/posts/golang_concurrency_technical_interview-11-04-2024" element={<GolangConcurrencyTechnicalInterview />} />
                    <Route path="/posts/arbitrage_computation_in_go-11-04-2024" element={<ArbitrageComputationInGo />} />
                    <Route path="/posts/concurrency_paterns_in_go-10-04-2024" element={<ConcurrencyPaternsInGo />} />
                    <Route path="/posts/porting-fermi-to-cpp-03-04-2024" element={<PortingFermiToCpp />} />
                    <Route path="/posts/patagonia-trip-2024-26-03-2024" element={<PatagoniaTrip2024 />} />
                    <Route path="/posts/hackerrank-cache-24-03-2024" element={<HackerrankCache />} />
                    <Route path="/posts/minimum-coins-problem-21-02-2024" element={<MinimumCoinsProblem />} />
                    <Route path="/posts/esp32-tools-25-01-2024" element={<Esp32Tools />} />
                    <Route path="/posts/propeller-clock-23-01-2024" element={<NewPost />} />
                    <Route path="/posts/second-day-canoe-06-12-2023" element={<SecondDayWithCanoe />} />
                </Routes>
            </body>
            <footer></footer>
        </BrowserRouter>
    )
}

export default App
