import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { InlineCode as IC, Toml, Rust, Bash } from '../CodeSnippets'

export default function ProgrammingBitcoinFiniteFieldsImprovements() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])

    return (
        <section>
            <h1>Programming Bitcoin</h1>
            <img src="/pics/bitcoin_logo.png" alt="Bitcoin Logo" width="150px"></img>
            <br></br>
            <h2>Finite Fields - Improvements (larger precision)</h2>
            <p>
                Elliptic curve cryptography in Bitcoin uses numbers that should be represent with 256 bits, that makes
                clear that using the native u32 or u64 Rust types will is a limitation. For accomplishing it, we are
                going to use the <a href="https://docs.rs/num-bigint/0.4.3/num_bigint/">num-bigint</a> crate. Reading
                the documentation seems that the crate allows to represent integers in every precision, that means that
                we should be able to perform any kind of operation (addition, multiplication, module) with 256-bits
                numbers.
            </p>
            <p>
                Let start by adding <IC code="num-bigint" /> to our <IC code="cargo.toml" /> file. We will add{' '}
                <IC code="num" /> for instantiating the <IC code="BigUint::one()" /> and <IC code="BigUint::zero()" />{' '}
                traits which make the code cleaner when we represent the 0 and 1, respectively; and <IC code="hex" />{' '}
                for converting a string representation of an hexadecimal number (e.g. "12ab") in a byte array:
            </p>
            <Toml>{`\
[dependencies]
num-bigint = "0.4.3"
num = "0.4"
hex = "0.4.3"\
            `}</Toml>
            <p>
                Before using them, since we are going to implement different modules on the way, would it be better to
                separate the source code into modules. We can rename <IC code="src/main.rs" /> code as{' '}
                <IC code="src/finite_fields.rs" /> and create a file <IC code="src/lib.rs" /> with this content:
            </p>
            <Rust>{`mod finite_fields`}</Rust>
            <p>
                This indicates the library should expose the <IC code="finite_fields" /> module. After that the tree
                structure should look like:
            </p>
            <Bash>{`\
finite_fields
├── Cargo.lock
├── Cargo.toml
└── src
    ├── finite_field.rs
    ├── lib.rs\
            `}</Bash>
            <p>
                Now we can make the new packages visible in our <IC code="src/finite_fields.rs" />. The following are
                some structures we are going to use then:
            </p>
            <Rust>{`\
use num::{Integer, One};
use num_bigint::{BigInt, BigUint, ToBigInt};\
            `}</Rust>
            <p>
                The first step is to replace the u32 types we originally used the <IC code="BigUint" /> type:
            </p>
            <Rust>{`\
#[derive(PartialEq, Debug, Clone)]
pub struct FiniteField {
    number: BigUint,
    prime: BigUint,
}\
            `}</Rust>
            <p>
                Note that we removed the automatically derived Copy trait since BigUint doesn't implement it. We need to
                work with its Clone derived trait directly.
            </p>
            <p>
                Using BigUint, the Add, Sub and Mul operators don't change at all since BigUint implements them too. We
                have to slightly change the pow and Div operators, and also add a scale for the case we multiply the
                finite field by a scalar operator:
            </p>
            <Rust>{`\
impl FiniteField {
    ...
    pub fn scale(self, scalar: BigUint) -> FiniteField {
        FiniteField {
            number: (self.number * scalar) % self.prime.clone(),
            prime: self.prime,
        }
    }

    pub fn pow(self, exp: BigInt) -> FiniteField {
        let exp = exp.mod_floor(&(self.prime.clone() - BigUint::one()).to_bigint().unwrap());
        let exp = exp.to_biguint().unwrap();

        let exp = exp.modpow(&BigUint::one(), &(self.prime.clone() - BigUint::one()));

        FiniteField {
            number: self.number.modpow(&exp, &self.prime),
            prime: self.prime,
        }
    }
}

impl Div for FiniteField {
    type Output = FiniteField;

    fn div(self, rhs: FiniteField) -> FiniteField {
        self.check_equal_order_and_panic(&rhs);

        self.clone() * rhs.pow((self.prime - BigUint::from(2u32)).to_bigint().unwrap())
    }
}\
`}</Rust>
            <p>
                Additionally, I found very useful to implement the <IC code="From<(u32,u32)>" /> for when we want to
                generate finite fields objects from simple u32 types:
            </p>
            <Rust>{`\
impl From<(u32, u32)> for FiniteField {
    fn from(tuple: (u32, u32)) -> Self {
        FiniteField {
            number: BigUint::from(tuple.0),
            prime: BigUint::from(tuple.1),
        }
    }
}\
            `}</Rust>

            <p>Then, we can create finite fields like:</p>

            <Rust>{`let a = FiniteField::from((6, 11));`}</Rust>

            <p>
                Finally, when we work we elliptic curve cryptography, we will need to initialize finite fields with
                really large number, e.g: <b>483ada7726a3c4655da4fbfc0e1108a8fd17b448a68554199c47d08ffb10d4b8</b>. For
                those cases we can implement a <IC code="from_bytes_be" /> method (<b>be</b> stands for big-endian
                representation):
            </p>

            <Rust>{`\
pub fn from_bytes_be(number: &[u8], prime: &[u8]) -> Self {
    let number = BigUint::from_bytes_be(number);
    let prime = BigUint::from_bytes_be(prime);

    FiniteField { number, prime }
}\
            `}</Rust>

            <p>We can use it as:</p>

            <Rust>{`\
#[test]
fn test_from_bytes_be() {
    let a = FiniteField::from_bytes_be(&[0x01, 0x02], &[0x01, 0x12]);
    let b = FiniteField::from((258, 274));

    assert_eq!(a, b);
}\
            `}</Rust>

            <p>
                And for initializing very large number we could take advantage of the <IC code="hex" /> library
                previously added:
            </p>

            <Rust>{`\
let prime = hex::decode("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEFFFFFC2F").unwrap();
let gx = hex::decode("79be667ef9dcbbac55a06295ce870b07029bfcdb2dce28d959f2815b16f81798").unwrap();
let gy = hex::decode("483ada7726a3c4655da4fbfc0e1108a8fd17b448a68554199c47d08ffb10d4b8").unwrap();

let gx = FiniteField::from_bytes_be(&gx, &prime);
let gy = FiniteField::from_bytes_be(&gy, &prime);\
            `}</Rust>
        </section>
    )
}
