import React  from 'react';
import { Institution } from './commons'
import { Tool } from './commons'
import '../App.css'

interface ParentCompProps {
    date: string
    institution: Institution
    role: string
    summary: React.ReactNode
    skills: Tool[]
}

const ExperienceItem: React.FC<ParentCompProps> = (props) => {
    const { date, institution, role, summary, skills } = props

    return (
        <div>
            <span className="date">{date}</span>
            {' | '}
            <span className="institution">
                <a href={institution.url ? institution.url : '#'}>{institution.name}</a>
            </span>{' '}
            <br></br>
            <span className="role">{role}</span> <span className="experience-summary">{summary}</span> <br></br>
            <span className="skills">
                Skills:{' '}
                {skills.map((tool, index) => (
                    <span key={index}>
                        {tool.url ? <a href={tool.url}>{tool.name}</a> : <b>{tool.name}</b>}
                        {index !== skills.length - 1 ? ', ' : ''}
                    </span>
                ))}
            </span>
        </div>
    )
}

export default ExperienceItem
