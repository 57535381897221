import React  from 'react';
import hljs from 'highlight.js'
import { useEffect } from 'react'

import { CPP, Dockerfile, Yaml } from '../CodeSnippets'

export function PortingFermiToCpp() {
    useEffect(() => {
        hljs.highlightAll()
    }, [])
    return (
        <section>
            <h1>Porting Fermi to C++<br></br>A Nuclear Reactor FEM code</h1>

            <p>I decided some months ago to refactor this old code that was started around 2016 - 2017 when I was in Barcelona. At that time I think I was using Mercurial as version control (<b>hg</b>). The code was written in C and used by Federico Caccia in his Master Thesis, he coupled it with other codes, like CFD. Miguel Zavalla from BSC has also added coupling capabilities with one of his libraries developed at BSC. Then one day in 2019 I migrated everything to Git and put the code in GitHub. The code remained intact for years, until today.</p>

            <h2>From C to C++</h2>

            <p>I first created a <b>CMakeList.txt</b> in order to configure the code using <b>cmake</b> and make it more user-friendly. Then I wanted to change the input format and avoid using the original <b>*.fer</b> language. I thought that maybe something like TOML would it better. I started writting a TOML parser in C and I gave up quickly. I searched for libraries in C but I didn't find any that was easy to use. I decided then to port the code to C++. I thought this would enable using the STL library from C++ to represent most of the data structures the code is using. Until now, I was using linked lists and own implementations. This would decrease substantially the lines of code significantly and the amount of possible bugs. Searching I found the library TOML++ to parse the input files, now it was time to port the code from C to C++.</p>

            <p>Since C is a subset of C++, the only thing to do was to just change the compiler in <b>CMakeList.txt</b> from C to C++. I had to fix also some castings in the <b>malloc</b> calls but this part was easy. I had a C++ Fermi code!</p>

            <p>I added also a CI for GitHub actions with a format checker with the <b>clang-format</b> format tool and I decided to use a Test-Driven Development (TDD) approach from any incoming change. I started with the TOML parser which created a <b>Config</b> class from the TOML input file.</p>

            <CPP>{`\
class Config {
private:
  static vector<double> parse_array_from_table(toml::v3::table table, string key);

public:
  string mesh_file;
  map<string, BoundaryCondition> boundaries;
  map<string, Material> materials;
  uint groups;

  static optional<Config> parse(string_view toml);
};`
        }</CPP>

            <p>A use case for this would be something like this unit test that it is being executed in the CI using <b>ctest</b>:</p>

            <CPP>{`\
int test_parse_cross_sections() {
  static constexpr std::string_view some_toml = R"(
    [geometry]
    mesh = "cube.msh"
    boundaries = { S1 = "dirichlet", WALL8 = "neumann" }

    [materials]
    MAT1 = { D = [1.5], xs_a = [0.2], xs_f = [0.3], xs_s = [1.0], chi = [1.0] }
    MAT2 = { D = [3.1], xs_a = [0.3], xs_f = [1.3], xs_s = [1.2], chi = [1.3] }
  )";

  optional<Config> result = Config::parse(some_toml);
  if (!result)
    assert(false);

  Config config = result.value();

  auto mat1 = Material{
      .D = {1.5}, .xs_a = {0.2}, .xs_f = {0.3}, .xs_s = {1.0}, .chi = {1.0}};
  auto mat2 = Material{
      .D = {3.1}, .xs_a = {0.3}, .xs_f = {1.3}, .xs_s = {1.2}, .chi = {1.3}};

  map<string, Material> expected = {{"MAT1", mat1}, {"MAT2", mat2}};
  assert(mapsAreEqual(config.materials, expected));

  return 0;
}`
        }</CPP>

            <h2>Using Docker</h2>

            <p>One thing I realized was the large amount of dependencies the application was having. If anyone needed to use it, this would be definitely pure friction. Then I decided to create a Docker images containing most of the dependencies of Fermi: Petsc, MPI, CMake, GCC compiler, clang-format, etc.</p>

            <Dockerfile>{`\
# Use an existing base image that includes MPI and PETSc dependencies
FROM ubuntu:latest

# Install additional dependencies if needed
RUN apt-get update && apt-get install -y \
 git build-essential petsc-dev cmake libopenmpi-dev clang-format cmake

ENV PETSC_DIR=/usr/lib/petscdir/petsc3.15/x86_64-linux-gnu-real

# Copy project files
COPY . /fermi

# Set working directory
WORKDIR /fermi`
            }</Dockerfile>

            <p>And with this I have a dockerized environment for testing and run the CI on GitHub without having to install every time dependencies! I uploaded also the image to the GitHub Container Repository from were the CI is pulling everytime that needs to run.</p>

            <Yaml>{`\
# This starter workflow is for a CMake project running on a single platform. There is a different starter workflow if you need cross-platform coverage.
# See: https://github.com/actions/starter-workflows/blob/main/ci/cmake-multi-platform.yml
name: CMake on a single platform

on:
  push:
    branches: [ "master" ]
  pull_request:
    branches: [ "master" ]

env:
  # Customize the CMake build type here (Release, Debug, RelWithDebInfo, etc.)
  BUILD_TYPE: Release

jobs:
  build-and-test:
    runs-on: ubuntu-latest
    container:
      image: ghcr.io/gagiuntoli/fermi-dev:latest

    steps:
    - uses: actions/checkout@v3
      with:
        submodules: true

    - name: Check syntax
      run: |
        files=$(find src include -name '*.cpp' -o -name '*.hpp')
        issues=$(clang-format -style=file -output-replacements-xml $files | grep -c '<replacement ' || true)
        if [ $issues -gt 0 ]; then
          echo "Found formatting issues. Exiting."
          clang-format --dry-run $files
          exit 1
        fi
        echo "No syntax issues."

    - name: Create build directory
      run: mkdir -p build

    - name: Configure CMake
      run: cmake -Bbuild -H.

    - name: Build
      run: cmake --build build --config Release

    - name: Test
      run: ctest --test-dir build`
        }</Yaml>

            <p>Well this is the first part of the project, this is how the CI looks when it runs:</p>

            <img src="/pics/fermi/ci.png" alt="Fermi CI" width="100%"></img>

        </section>
    )
}
